// OrderItem.js
import React,{useState} from "react";
import { useNavigate } from "react-router-dom";

const OrderCloutreCard = ({ order, onReject }) => {


    // to handle the confirm, reject and ignore actions
    const navigate = useNavigate();

    const AfficherDetails = async () => {
      try {
        const response = await fetch(`https://worlddigitaldental.com/wddapi/orders/${order.id}/pdf`);
        if (response.ok) {
          const blob = await response.blob();
          const pdfUrl = URL.createObjectURL(blob);
          // Open the PDF in a new window
          window.open(pdfUrl, "_blank");
        } else {
          console.error("Failed to fetch PDF:", response.status);
          // Show a message in the dashboard
          alert("No PDF available for this order.");
        }
      } catch (error) {
        console.error("Error fetching PDF:", error);
        // Show a message in the dashboard
        alert("Error fetching PDF.");
      }
    };

    const AfficherPaiement = async () => {
      try {
        const response = await fetch(`https://worlddigitaldental.com/wddapi/orders/${order.id}/paymentimg`);
        if (response.ok) {
          const blob = await response.blob();
          const imageUrl = URL.createObjectURL(blob);
          // Open the image in a new window
          window.open(imageUrl, "_blank");
        } else {
          console.error("Failed to fetch image:", response.status);
          // Show a message in the dashboard
          alert("No image available for this order.");
        }
      } catch (error) {
        console.error("Error fetching image:", error);
        // Show a message in the dashboard
        alert("Error fetching image.");
      }
    };

    const delivered = async () => {
      const shouldDelete = window.confirm("Êtes-vous sûr que cette commande est livrée ?");
      
      if (shouldDelete) {
      try {
        const response = await fetch(`https://worlddigitaldental.com/wddapi/orders/${order.id}/delivered`, {
          method: "PUT",
        });
        if (response.ok) {
          navigate("/admin/commandes");
        } else {
          throw new Error("Error confirming order");
        }
      } catch (error) {
        console.error("Error confirming order:", error);
      }
    }
    }
    
    const handleReject = async () => {
      
      try {
        const response = await fetch(`https://worlddigitaldental.com/wddapi/orders/${order.id}`, {
          method: "DELETE",
        });
        if (response.ok) {
          onReject(order);

        } else {
          throw new Error("Error deleting order");
        }
      } catch (error) {
        console.error("Error deleting order:", error);
      }
    };

    
    const buttonStyle = {
        backgroundColor: "#4CAF50", // Green when checked, gray when unchecked
        color: "white", // White text when checked, dark gray text when unchecked
        cursor: "pointer", // Pointer cursor when checked, not-allowed when unchecked
      };

  return (
          <div key={order.id} className="order-item border p-4 mb-4 flex flex-col items-start shadow-lg rounded-lg bg-blueGray-100 border-0">
            <h3 className="text-lg font-bold mb-2">{order.username} : {order.id}-{order.user_id}</h3>
            <p>Email: {order.email}</p>
            <p>Telephone: {order.mobile}</p>
            <p>Type: {order.type}</p>
            <p>Date: {order.date}</p>

            <button
              className="bg-blue-500 text-white px-4 py-2 mt-2 rounded"
              onClick={AfficherDetails}
              style={{ backgroundColor: "#14939C" }}
            >
              Afficher les détails
            </button>

            <button
              className="bg-blue-500 text-white px-4 py-2 mt-2 rounded"
              onClick={AfficherPaiement}
              style={{ backgroundColor: "#14939C" }}
            >
              Afficher le method de paiement
            </button>


            <button
              className="bg--500 text-white px-4 py-2 mt-2 rounded"
              onClick={delivered}
                style={{ backgroundColor: "#4CAF50" }}
            >
              Commande livrée
            </button>

          </div>
  );
};

export default OrderCloutreCard;
