import React ,{ useState, useEffect } from "react";
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';

// components

export default function CardSettings() {

  const [userData, setUserData] = useState({
    username: '',
    email: '',
    name: '',
    lastname: '',
    user_type: '',
    matricule: '',
    adress: '',
    ville: '',
    pays: '',
    fix_number: '',
    mobile_number: '',
  });

  const [disabledFields, setDisabledFields] = useState(['username', 'email', 'name', 'lastname', 'user_type','matricule','mobile_number']);

  const auth = useAuthUser()
  const id = auth.id

  useEffect(() => {
    // Fetch user data from the database and update the state
    // Replace the following with your actual API endpoint
    fetch('https://worlddigitaldental.com/wddapi/users/'+id)
      .then( response => response.json())
      .then( userData => {
        setUserData(userData.userData)
      }
      )
      .catch((error) => console.error('Error fetching user data:', error));
  }, []); // Empty dependency array to run the effect only once on mount

  const handleChange = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Send the modified data to the backend for updating in the database
    // Use the fetch API or your preferred method for making API requests
    fetch('https://worlddigitaldental.com/wddapi/users', {
      method: 'PUT', // or 'POST' depending on your API
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userData),
    })
      .then((response) => response.json())
      .then((data) => console.log('Data updated successfully:', data))
      .catch((error) => console.error('Error updating data:', error));
  };

  const handleEdit = (e) => {
    e.preventDefault();
  
    // Create a payload with only the specified fields to update
    const payload = {
      adress: userData.adress,
      ville: userData.ville,
      pays: userData.pays,
      fix_number: userData.fix_number,
      doneby: "user",
    };
  
    // Update the endpoint URL to include the user ID
    fetch('https://worlddigitaldental.com/wddapi/users/' + id, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => console.log('Data updated successfully:', data))
      .catch((error) => console.error('Error updating data:', error));
  };
  

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold">Mon compte</h6>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form>
            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
            Informations de l'utilisateur
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Nom d'utilisateur:
                  </label>
                  <input
                    type="text"
                    name="username"
                    value={userData.username}
                    placeholder="Nom d'utilisateur"
                    // onChange={handleChange}
                    disabled={disabledFields.includes('username')}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Adresse e-mail:
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={userData.email}
                    disabled={disabledFields.includes('email')}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Nom:
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={userData.name}
                    disabled={disabledFields.includes('name')}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Prénom:
                  </label>
                  <input
                    type="text"
                    name="user_type"
                    value={userData.lastname}
                    disabled={disabledFields.includes('lastname')}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
            </div>

            <hr className="mt-6 border-b-1 border-blueGray-300" />

            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
            Coordonnées 
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Address du cabinet:
                  </label>
                  <input
                    type="text"
                    name="adress"
                    value={userData.adress}
                    onChange={handleChange}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Ville:
                  </label>
                  <input
                    type="text"
                    name="ville"
                    value={userData.ville}
                    onChange={handleChange}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Pays:
                  </label>
                  <input
                    type="text"
                    name="pays"
                    value={userData.pays}
                    onChange={handleChange}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Matricule fiscal ou registre d'entreprise:
                  </label>
                  <input
                    type="text"
                    name="matricule"
                    value={userData.matricule}
                    disabled={disabledFields.includes('matricule')}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
            </div>

            <hr className="mt-6 border-b-1 border-blueGray-300" />

            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Contact
            </h6>
            <div className="flex flex-wrap">
            
            {/* Work Number */}
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="workNumber"
                >
                  NUMÉRO DE TÉLÉPHONE  
                </label>
                <input
                  type="text"
                  name="mobile_number"
                  value={userData.mobile_number}
                  id="mobile_number"
                  disabled={disabledFields.includes('mobile_number')}
                  placeholder="70 00 00 00"
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                />
              </div>
            </div>

            {/* Telephone Number */}
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="telephoneNumber"
                >
                  NUMÉRO DE TÉLÉPHONE FIXE (OPTIONNEL)
                </label>
                <input
                  type="text"
                  name="fix_number"
                  value={userData.fix_number}
                  id="fix_number"
                  onChange={handleChange}
                  placeholder="50 00 00 00"
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                />
              </div>
            </div>

          </div>

          
          <br/>

          <div className="text-center flex justify-between">
            <button
              className="bg-lightBlue-600 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
              onClick={handleEdit}
            >
              Confirmer
            </button>
          </div>
          </form>
        </div>
      </div>
    </>
  );
}
