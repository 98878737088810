import React, { useState } from "react";

const ConfirmOrderbyUser = ({ order, onDelete }) => {

  const [stlError, setStlError] = useState(null); // State for STL download error
  const [pdfError, setpdfError] = useState(null); // State for PDF download error


  const showPDF = async () => {
    try {
      const pdfResponse = await fetch(`https://worlddigitaldental.com/wddapi/orders/${order.id}/secondpdf`);
      const pdfData = await pdfResponse.arrayBuffer();
  
      const pdfBlob = new Blob([pdfData], { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(pdfBlob);
  
      // Open the PDF in a new window
      window.open(pdfUrl, '_blank');
    } catch (error) {
      console.error("Error fetching or displaying PDF:", error);
    }
  };

  const showPDF2 = async () => {
    try {
      const pdfResponse = await fetch(`https://worlddigitaldental.com/wddapi/orders/${order.id}/thirdpdf`);
      if (!pdfResponse.ok) {
        throw new Error("Error downloading PDF");
      }
      else {
      const pdfData = await pdfResponse.arrayBuffer();

      const pdfBlob = new Blob([pdfData], { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(pdfBlob);

      // Open the PDF in a new window
      window.open(pdfUrl, '_blank');
      }
    } catch (error) {
      console.error("Error fetching or displaying PDF:", error);
      setpdfError("Aucun fichier PDF disponible pour cette commande."); // Set error message
    }
  }

  const downloadStl = async () => {
    try {
      const stlResponse = await fetch(`https://worlddigitaldental.com/wddapi/orders/${order.id}/stl`);
      if (!stlResponse.ok) {
        throw new Error("Error downloading STL");
      }
      else {
              
        const stlData = await stlResponse.arrayBuffer();

        const stlBlob = new Blob([stlData], { type: 'application/stl' }); 
        const stlUrl = URL.createObjectURL(stlBlob);

        window.open(stlUrl, '_blank');
      }
    }
    catch (error) {
      console.error("Error fetching or displaying STL:", error);
      setStlError("Aucun fichier STL disponible pour cette commande."); // Set error message
    }
  };


  return (
    <div key={order.id} className="order-item border p-4 mb-4 flex flex-col items-start shadow-lg rounded-lg bg-blueGray-100 border-0">
      <h3 className="text-lg font-bold mb-2">{order.firstName} {order.lastName}</h3>
      <p>Type: {order.type}</p>
      <p>État: {order.status}</p>
      <p>Date: {order.date}</p>
      <button
        className="bg-blue-500 text-white px-4 py-2 mt-2 rounded"
        onClick={showPDF}
        style={{ backgroundColor: "#14939C" }}
      >
        Télécharger le PDF 1 
      </button>

      <button
        className="bg-blue-500 text-white px-4 py-2 mt-2 rounded"
        onClick={showPDF2}
        style={{ backgroundColor: "#14939C" }}
      >
        Télécharger le PDF 2 
      </button>
      {pdfError && (
        <div className="text-red-500 mt-2">
          {pdfError}
        </div>
      )}

      <button
        className="bg-blue-500 text-white px-4 py-2 mt-2 rounded"
        onClick={downloadStl}
        style={{ backgroundColor: "#14939C" }}
      >
        Télécharger le fichier STL
      </button>
      {stlError && (
        <div className="text-red-500 mt-2">
          {stlError}
        </div>
      )}

      {order.status === "Complété" && (
        <div
          className="bg-red-500 text-white text-center px-4 py-2 mt-2 rounded"
          style={{ backgroundColor: "lightcoral" }}
        >
          Pas encore livré
        </div>
      )}

      {order.status === "Livré" && (
        <div
          className="bg-green-500 text-center text-white px-4 py-2 mt-2 rounded"
            style={{ backgroundColor: "#4CAF50" }}
        >
          Commande livrée
        </div>
      )}


    </div>
  );
};

export default ConfirmOrderbyUser;
