import React, {useState,useEffect} from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";


// components

import TableDropdown from "components/Dropdowns/TableDropdown.js";

export default function UserOrders({ color }) {

  const {userId} = useParams();
  console.log("User ID:", userId);

  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://worlddigitaldental.com/wddapi/user/${userId}/orders`); 
        setOrders(response.data.orders); 
        setFilteredOrders(response.data.orders);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []); 


  const filterOrdersByStatus = (status) => {
    if (status === selectedStatus) {
      setSelectedStatus("");
      setFilteredOrders(orders);
    } else {
      setSelectedStatus(status);
      const filtered = orders.filter((order) => order.status === status);
      setFilteredOrders(filtered);
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "En attente":
        return "text-orange-500";
      case "A Confirmé":
        return "text-blue-500";
      case "En cours":
        return "text-purple-500";
      case "Complété":
        return "text-green-500";
      case "Annulé":
        return "text-red-500";
      case "Livré":
        return "text-black-500"
      default:
        return ""; // Default color or empty string
    }
  };
  
  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
        <br/>
        <div className="flex flex-wrap items-center mb-4">
          <span className="mr-2 font-semibold text-blueGray-700">Filtrer par statut :</span>
          {["En attente", "A Confirmé", "En cours", "Complété", "Annulé"].map((status) => (
            <button
              key={status}
              className={`${
                selectedStatus === status
                  ? "bg-blue-500 hover:bg-blue-600 text-white"
                  : "bg-gray-200 hover:bg-gray-300 text-gray-700"
              } font-bold py-2 px-4 rounded mr-2 transition duration-300`}
              onClick={() => filterOrdersByStatus(status)}
            >
              {status}
            </button>
          ))}
        </div>

          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <br/>
              <h3
                className={
                  "font-semibold text-lg " +
                  (color === "light" ? "text-blueGray-700" : "text-white")
                }
              >
              </h3>
              <br/>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Nom du patient
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Type de commande
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  État
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Date de création
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Référence (ID)
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                ></th>
              </tr>
            </thead>


            <tbody>
            {filteredOrders.length > 0 ? (
            filteredOrders.map((order, index) => (
              <tr key={index}>
                <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                  <span
                    className={
                      "ml-3 font-bold " +
                      (color === "light" ? "text-blueGray-600" : "text-white")
                    }
                  >
                    {order.firstName} {order.lastName}
                  </span>
                </th>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  {order.type}
                </td>
                <td className={`border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 ${getStatusColor(order.status)}`}>
                  <i className={`fas fa-circle mr-2`}></i>{" "}
                  {order.status}
                </td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  <div className="flex">
                    <span
                      className={
                        "ml-3 font-bold " +
                        (color === "light" ? "text-blueGray-600" : "text-white")
                      }
                    >
                      {order.date}
                    </span>
                  </div>
                </td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  <div className="flex items-center">
                    <span
                      className={
                        "ml-3 font-bold " +
                        (color === "light" ? "text-blueGray-600" : "text-white")
                      }
                    >
                      {order.user_id} {"-"} {order.id}
                    </span>
                  </div>
                </td>
              </tr>
            ))
            ) : (
                <tr>
                  <td colSpan="5" className="text-center py-4 font-bold text-gray-700">
                    No orders found.
                  </td>
                </tr>
              )}
          </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

UserOrders.defaultProps = {
  color: "light",
};

UserOrders.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};