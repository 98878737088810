// OrderItem.js
import React,{useState} from "react";
import { useNavigate } from "react-router-dom";

const ConfirmOrder = ({ order, onReject }) => {


    // to handle the confirm, reject and ignore actions
    const navigate = useNavigate();
    // const handleAttach = () => {
    
    //     // Navigate to another page (replace '/confirmation-page' with the desired path)
    //     navigate(`/admin/commandes_en_attente/pdf/${order.id}`);
    //   };

    const handleAffiche = async () => {
      try {
        const pdfResponse = await fetch(`https://worlddigitaldental.com/wddapi/orders/${order.id}/pdf`);
        const pdfData = await pdfResponse.arrayBuffer();
    
        const pdfBlob = new Blob([pdfData], { type: 'application/pdf' });
        const pdfUrl = URL.createObjectURL(pdfBlob);
    
        // Open the PDF in a new window
        window.open(pdfUrl, '_blank');
      } catch (error) {
        console.error("Error fetching or displaying PDF:", error);
      }
    };
    
    const handleIgnore = async () => {
      const shouldDelete = window.confirm("Êtes-vous sûr de vouloir supprimer cette commande ?");
      
      if (shouldDelete) {
          try {
              const response = await fetch(`https://worlddigitaldental.com/wddapi/orders/${order.id}`, {
                  method: "DELETE",
              });
              if (response.ok) {
                  onReject(order);
                  navigate("/admin/order_rejected");
              } else {
                  throw new Error("Error deleting order");
              }
          } catch (error) {
              console.error("Error deleting order:", error);
          }
          console.log("Order Deleted:", order);
      }
    };

    
    const buttonStyle = {
        backgroundColor: "#4CAF50", // Green when checked, gray when unchecked
        color: "white", // White text when checked, dark gray text when unchecked
        cursor: "pointer", // Pointer cursor when checked, not-allowed when unchecked
      };

  return (
          <div key={order.id} className="order-item border p-4 mb-4 flex flex-col items-start shadow-lg rounded-lg bg-blueGray-100 border-0">
            <h3 className="text-lg font-bold mb-2">{order.username} : {order.id}-{order.user_id}</h3>
            <p>Nom de patient: {order.patientName}</p>
            <p>Email: {order.email}</p>
            <p>Téléphone: {order.mobile}</p>
            <p>Date: {order.date}</p>
            <button
              className="bg-blue-500 text-white px-4 py-2 mt-2 rounded"
              onClick={handleAffiche}
              style={{ backgroundColor: "#14939C" }}
            >
              Affichez le PDF
            </button>

            <button
              className="bg-red-500 text-white px-4 py-2 mt-2 rounded"
              onClick={handleIgnore}
            //   style={{ backgroundColor: "#14939C" }}
            >
              Supprimer
            </button>
          </div>
  );
};

export default ConfirmOrder;
