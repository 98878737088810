import React from 'react';

const ErrorMessage = ({ message, onClose }) => {
  return (
    <div className="error-message">
      <div className="overlay" onClick={onClose}></div>
      <div className="message-box">
        <span className="close-btn" onClick={onClose}>
          &times;
        </span>
        <p style={{"fontSize":30}}>{message}</p>
      </div>
    </div>
  );
};

export default ErrorMessage;
