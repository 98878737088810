// OrderItem.js
import React,{useState} from "react";
import { useNavigate } from "react-router-dom";

const UserRequests = ({ user, onConfirm, onReject }) => {

    // to handle the confirm, reject and ignore actions
    const navigate = useNavigate();
    const handleConfirm = () => {
        // Handle confirmation logic
        onConfirm(user);

        // For simplicity, we are just logging the confirmation for now
        console.log("User confirmed:", user);
    
        // Navigate to another page (replace '/confirmation-page' with the desired path)
        navigate("/admin/accept_user");
      };
    
      const handleReject = () => {
        
        // For simplicity, we are just logging the ignore for now
        console.log("Order rejected:", user);

        // Handle rejection logic
        onReject(user);

        navigate("/admin/reject_user")

      };

    
    const buttonStyle = {
        backgroundColor: "#4CAF50", // Green when checked, gray when unchecked
        color: "white", // White text when checked, dark gray text when unchecked
        cursor: "pointer", // Pointer cursor when checked, not-allowed when unchecked
      };

  return (
          <div key={user.id} className="order-item border p-4 mb-4 flex flex-col items-start shadow-lg rounded-lg bg-blueGray-100 border-0">
            <h3 className="text-lg font-bold mb-2">Demande de: {user.username}</h3>
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
            <div className="grid grid-cols-2 order-grid">
            <p>Nom d'utilisateur: {user.username}</p>
            <p>Nom: {user.name}</p>
            <p>Sexe: {user.gender}</p>
            <p>Travail: {user.user_type}</p>
            <p>Email: {user.email}</p>
            <p>Num: {user.fix_number}</p>
            <p>Mobile: {user.mobile_number}</p>
            <p>Adresse: {user.adress}</p>
            <p>Ville: {user.ville}</p>
            <p>Pays: {user.pays}</p>
            <p>Matricul Fiscal: {user.matricule}</p>
            </div>
            </div>
            <div className="mt-2 flex justify-end w-full">
              <button
                className="bg-green-500 text-white px-4 py-2 mr-2 rounded"
                onClick={(handleConfirm)}
                style={buttonStyle}
                // style={{ backgroundColor: "#2dce89" }}
              >
                Confirmer
              </button>
              <button
                className="bg-red-500 text-white px-4 py-2 mr-2 rounded"
                onClick={(handleReject)}
              >
                Rejeter
              </button>
            </div>
          </div>
  );
};

export default UserRequests;

