import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StyleSheet } from '@react-pdf/renderer';
import { faFilePdf, faUpload } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams } from 'react-router-dom';

const PdfUploadPage = () => {
  const { register, handleSubmit, errors, setValue, watch } = useForm();
  const [selectedFileName, setSelectedFileName] = useState('');

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');

  const [filesecond, setsecondFile] = useState(null);
  const [fileNamesecond, setsecondFileName] = useState('');

  const [stl, setstlFile] = useState(null);
  const [stlName, setstlFileName] = useState('');

  const navigate = useNavigate();

  const { orderId } = useParams();

  const onSubmit = async (file, filesecond) => {
    try {
      if (file && filesecond) {
        const formData = new FormData();
        formData.append('file', file);

        const formData2 = new FormData();
        formData2.append('file', filesecond);

        const formData3 = new FormData();
        formData3.append('file', stl);

        // Replace 'YOUR_UPLOAD_API_ENDPOINT' with your actual API endpoint
        const response = await fetch(`https://worlddigitaldental.com/wddapi/orders/${orderId}/secondpdf`, {
          method: 'PUT',
          body: formData,
        });

        const response2 = await fetch(`https://worlddigitaldental.com/wddapi/orders/${orderId}/thirdpdf`, {
          method: 'PUT',
          body: formData2,
        });

        const response3 = await fetch(`https://worlddigitaldental.com/wddapi/orders/${orderId}/stl`, {
          method: 'PUT',
          body: formData3,
        });
        

        const ChangeResponse = await fetch(`https://worlddigitaldental.com/wddapi/orders/${orderId}/complete`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ status: 'Envoyée' }),
        
        } )

        if (response.ok && ChangeResponse.ok) {
          console.log('PDF uploaded successfully');
          navigate('/admin/pdf_envoyee');
          // Handle success (e.g., show a success message)
        } else {
          console.error('Failed to upload PDF');
          // Handle failure (e.g., show an error message)
        }
      } else {
        console.error('No PDF selected');
        // Handle the case where no PDF is selected (show an error message, for example)
      }
    } catch (error) {
      console.error('An error occurred:', error);
      // Handle other errors
    }
  };

  const handleFileChange = (event, type) => {
    
    if (type === 'second') {
      const uploadedFile = event.target.files[0];
      setFile(uploadedFile);
    
      // Extract the file name and set it in the state
      const fileName = uploadedFile ? uploadedFile.name : '';
      setFileName(fileName);
    }

    else if (type === 'third') {
      const uploadedFile = event.target.files[0];
      setsecondFile(uploadedFile);
    
      // Extract the file name and set it in the state
      const fileNamesecond = uploadedFile ? uploadedFile.name : '';
      setsecondFileName(fileNamesecond); 
    }

    else if (type === 'stl') {
      const uploadedFile = event.target.files[0];
      setstlFile(uploadedFile);
    
      // Extract the file name and set it in the state
      const stlName = uploadedFile ? uploadedFile.name : '';
      setstlFileName(stlName);
    }
  }

  // const handleFileChange = (event) => {
  //   const uploadedFile = event.target.files[0];
  //   setFile(uploadedFile);
    
  //   // Extract the file name and set it in the state
  //   const fileName = uploadedFile ? uploadedFile.name : '';
  //   setFileName(fileName);
  // };

  // const handleFileChangesecond = (event) => {
  //   const uploadedFile = event.target.files[0];
  //   setsecondFile(uploadedFile);
    
  //   // Extract the file name and set it in the state
  //   const fileName = uploadedFile ? uploadedFile.name : '';
  //   setsecondFileName(fileName);
  // };

  const styles = StyleSheet.create({
    acceptButton: {
      backgroundColor: file ? '#2ecc71' : '#ddd',
      color: file ? 'white' : '#555',
      cursor: file ? 'pointer' : 'not-allowed',
      padding: '10px 20px',
      borderRadius: 5,
    },
  });

  return (
    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0 container mx-auto mt-10 p-8">      
    <h2 className="text-2xl font-bold mb-6">Upload Your PDF</h2>
        <label className="block text-gray-700 text-sm font-bold mb-2">
          <FontAwesomeIcon icon={faFilePdf} className="mr-2" />
          Attach PDF:
        </label>
        <div className="flex items-center mb-4 border-0 rounded-lg" style={{backgroundColor:"#14939C"}}>
          <label
            htmlFor="pdf"
            className="cursor-pointer bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            <FontAwesomeIcon icon={faUpload} className="mr-2" />
            Select PDF
          </label>
          <input
            type="file"
            name="pdf"
            id="pdf"
            accept=".pdf"
            onChange={(e) => handleFileChange(e, 'second')}
            className="hidden"
          />
        </div>
        {file && (
          <p className="text-gray-700">
            Selected PDF: <span className="font-bold">{fileName}</span>
          </p>
        )}
        {errors && (
          <small className="text-red-500">{errors.pdf.message || 'An error occurred.'}</small>
        )}

        <label className="block text-gray-700 text-sm font-bold mb-2">
          <FontAwesomeIcon icon={faFilePdf} className="mr-2" />
          Attach the second PDF:
        </label>
        <div className="flex items-center mb-4 border-0 rounded-lg" style={{backgroundColor:"#14939C"}}>
          <label
            htmlFor="pdf2"
            className="cursor-pointer bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            <FontAwesomeIcon icon={faUpload} className="mr-2" />
            Select PDF
          </label>
          <input
            type="file"
            name="pdf2"
            id="pdf2"
            accept=".pdf"
            onChange={(e) => handleFileChange(e, 'third')}
            className="hidden"
          />
        </div>
        {filesecond && (
          <p className="text-gray-700">
            Selected PDF: <span className="font-bold">{fileNamesecond}</span>
          </p>
        )}
        {errors && (
          <small className="text-red-500">{errors.pdf.message || 'An error occurred.'}</small>
        )}


        <label className="block text-gray-700 text-sm font-bold mb-2">
          <FontAwesomeIcon icon={faFilePdf} className="mr-2" />
          Attach the STL file:
        </label>
        <div className="flex items-center mb-4 border-0 rounded-lg" style={{backgroundColor:"#14939C"}}>
          <label
            htmlFor="stl"
            className="cursor-pointer bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            <FontAwesomeIcon icon={faUpload} className="mr-2" />
            Select PDF
          </label>
          <input
            type="file"
            name="stl"
            id="stl"
            accept=".stl"
            onChange={(e) => handleFileChange(e, 'stl')}
            className="hidden"
          />
        </div>
        {stl && (
          <p className="text-gray-700">
            Selected STL file: <span className="font-bold">{stlName}</span>
          </p>
        )}
        {errors && (
          <small className="text-red-500">{errors.pdf.message || 'An error occurred.'}</small>
        )}
        <br />
        <button
          type="submit"
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
          onClick={() => onSubmit(file, filesecond)}
          style={styles.acceptButton}
          disabled={!file}
          >
          <FontAwesomeIcon icon={faUpload} className="mr-2" />
          Envoyer
        </button>
    </div>
  );
};

export default PdfUploadPage;
