// OrderItem.js
import React,{useState} from "react";
import { useNavigate } from "react-router-dom";

const ConfirmOrderbyUser = ({ order, onReject }) => {

    const showPdf = () => {
        // Replace 'your_pdf_url' with the actual URL of the PDF
        window.open('your_pdf_url', '_blank');
      };

    // to handle the confirm, reject and ignore actions
    const navigate = useNavigate();
    const handleAttach = () => {
    
        // Navigate to another page (replace '/confirmation-page' with the desired path)
        navigate(`/admin/confirmedbyyser/pdf/${order.id}`);
      };
    
      const handleReject = async () => {
        
        const shouldDelete = window.confirm("Are you sure you want to delete this order?");
      
        if (shouldDelete) {
            try {
                const response = await fetch(`https://worlddigitaldental.com/wddapi/orders/${order.id}`, {
                    method: "DELETE",
                });
                if (response.ok) {
                  onReject(order);
                  navigate("/admin/order_rejected");
                } else {
                    throw new Error("Error deleting order");
                }
            } catch (error) {
                console.error("Error deleting order:", error);
            }
            console.log("Order Deleted:", order);
        }
      };

    const AfficherPaiement = async () => {
      try {
        const response = await fetch(`https://worlddigitaldental.com/wddapi/orders/${order.id}/paymentimg`);
        if (response.ok) {
          const blob = await response.blob();
          const imageUrl = URL.createObjectURL(blob);
          // Open the image in a new window
          window.open(imageUrl, "_blank");
        } else {
          console.error("Failed to fetch image:", response.status);
          // Show a message in the dashboard
          alert("Paiement sur place");
        }
      } catch (error) {
        console.error("Error fetching image:", error);
        // Show a message in the dashboard
        alert("Error fetching image.");
      }
    };

    const AfficherDetails = async () => {
      try {
        const response = await fetch(`https://worlddigitaldental.com/wddapi/orders/${order.id}/pdf`);
        if (response.ok) {
          const blob = await response.blob();
          const pdfUrl = URL.createObjectURL(blob);
          // Open the PDF in a new window
          window.open(pdfUrl, "_blank");
        } else {
          console.error("Failed to fetch PDF:", response.status);
          // Show a message in the dashboard
          alert("No PDF available for this order.");
        }
      } catch (error) {
        console.error("Error fetching PDF:", error);
        // Show a message in the dashboard
        alert("Error fetching PDF.");
      }
    };
       
    const buttonStyle = {
        backgroundColor: "#4CAF50", // Green when checked, gray when unchecked
        color: "white", // White text when checked, dark gray text when unchecked
        cursor: "pointer", // Pointer cursor when checked, not-allowed when unchecked
      };

  return (
          <div key={order.id} className="order-item border p-4 mb-4 flex flex-col items-start shadow-lg rounded-lg bg-blueGray-100 border-0">
            <h3 className="text-lg font-bold mb-2">{order.username}: {order.id}-{order.user_id}</h3>
            <p>email: {order.email}</p>
            <p>Telephone: {order.mobile}</p>
            <p>Type: {order.type}</p>
            <p>Date: {order.date}</p>
            <button
              className="bg-blue-500 text-white px-4 py-2 mt-2 rounded"
              onClick={handleAttach}
              style={{ backgroundColor: "#14939C" }}
            >
              Attachez les PDF
            </button>

            <button
              className="bg-blue-500 text-white px-4 py-2 mt-2 rounded"
              onClick={AfficherDetails}
              style={{ backgroundColor: "#14939C" }}
            >
              Afficher les détails
            </button>

            <button
              className="bg-blue-500 text-white px-4 py-2 mt-2 rounded"
              onClick={AfficherPaiement}
              style={{ backgroundColor: "#14939C" }}
            >
              Afficher le method de paiement
            </button>

            <button
              className="bg-red-500 text-white px-4 py-2 mt-2 rounded"
              onClick={handleReject}
            //   style={{ backgroundColor: "#14939C" }}
            >
              Cancel
            </button>
          </div>
  );
};

export default ConfirmOrderbyUser;
