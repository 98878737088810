import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const OrderRejected = ({ order, onDelete }) => {
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [rejectionReason, setRejectionReason] = useState(null); // State for STL download error
  const navigate = useNavigate();

  const handleCheckboxChange = () => {
    setIsCheckboxChecked(!isCheckboxChecked);
  };

  const handleDelete = () => {
    console.log("Order rejected:", order);
    onDelete(order);
    navigate("/user/rejection");
  };

  const showRejectionReason = () => {
    if (order.admin_rejection_reason) {
      setRejectionReason(order.admin_rejection_reason);
    } else if (order.user_rejection_reason) {
      setRejectionReason(order.user_rejection_reason);
    }
    else {
      setRejectionReason("Aucune raison de rejet disponible pour cette commande.");
    }
  };


  return (
    <div key={order.id} className="order-item border p-4 mb-4 flex flex-col items-start shadow-lg rounded-lg bg-blueGray-100 border-0">
      <h3 className="text-lg font-bold mb-2">{order.firstName} {order.lastName}</h3>
      <p>Type: {order.type}</p>
      <p>État: {order.status}</p>
      <p>Date: {order.date}</p>
      <button
        className="bg-blue-500 text-white px-4 py-2 mt-2 rounded"
        onClick={showRejectionReason}
        style={{ backgroundColor: "#14939C" }}
      >
        Afficher les raisons de rejet
      </button>

      {rejectionReason && (
        <div className="text-red-500 mt-2 ml-3 font-bold">
          {rejectionReason}
        </div>
      )}

      <div className="mt-2 flex justify-end w-full">
        <button
          className="bg-red-500 text-white px-4 py-2 mr-2 rounded"
          onClick={handleDelete}
        >
          Supprimer
        </button>
      </div>
    </div>
  );
};

export default OrderRejected;
