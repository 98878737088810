import React, { useState, useEffect } from "react";
import 'assets/styles/OrderConfirmation.css';
import { useNavigate } from "react-router-dom";
import ConfirmOrderbyUser from "components/Cards/CardOrderConfirmbyUser";

const ConfirmedByUser = ({ onConfirm, onReject }) => {
  const [orders, setOrders] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch orders from your API and set them in state
    const fetchOrders = async () => {
      try {
        const response = await fetch("https://worlddigitaldental.com/wddapi/orders/encour");
        const data = await response.json();
        setOrders(data.orders);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    fetchOrders();
  }, []); // Run this effect only once on component mount

  const handleConfirm = (order) => {
    // Logic for confirming the order
    console.log("Order confirmed:", order);
  };

  const handleReject = (order) => {
    // Logic for rejecting the order
    console.log("Order rejected:", order);
    navigate("/admin/order_rejected");
  };

  return (
    <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
      {orders === undefined || orders.length === 0 ? (
        <>
        <div className=" mt-20">
        <p className="text-center text-2xl font-bold py-24">Aucune commande confirmée par l'utilisateur.</p>
        </div>
        </>
      ) : (
        <div className="grid grid-cols-3 order-grid">
          {orders.map((order) => (
            <ConfirmOrderbyUser
              key={order.id}
              order={order}
              onConfirm={handleConfirm}
              onReject={handleReject}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ConfirmedByUser;
