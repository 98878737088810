import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// components

import Navbar from "components/Navbars/AuthNavbar.js";
import FooterSmall from "components/Footers/FooterSmall.js";

// views

import Login from "views/auth/Login.js";
import Register from "views/auth/Register.js";
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';

import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated'
import LoginAdmin from "views/auth/AdminLogin";

import Waiting from "views/auth/Waiting";

import ForgetPassword from "views/auth/ForgetPassword";
import ResetPassword from "views/auth/ResetPassword";
import PasswordChanged from "views/auth/PasswordChanged";

export default function Auth() {

  const isAuthenticated = useIsAuthenticated()
  const auth = useAuthUser(); // Add your logic to check if the user is authenticated

  if (isAuthenticated() && auth?.name === "user") {
    return (
      <>
        <Navigate to="/user/dashboard" />
      </>
    );
  }
  else if (isAuthenticated() && auth?.name === "admin") {
    return (
      <>
        <Navigate to="/admin/users_requests" />
      </>
    );
  }
else{
  return (
    <>
      <Navbar transparent />
      <main>
        <section className="relative w-full h-full py-40 min-h-screen">
          <div
            className="absolute top-0 w-full h-full bg-blueGray-800 bg-no-repeat bg-cover"
            style={{
              backgroundImage:
                "url(" + require("assets/img/Cover1.png") + ")",
              backgroundColor: '#14939C',
            }}
          ></div>
          
          <Routes>
            <Route path="login" element={<Login/>} />
            <Route path="register" element={<Register/>} />
            <Route path="loginadmin" element={<LoginAdmin/>} />
            <Route path="waiting" element={<Waiting/>} />
            <Route path="changepassword" element={<ForgetPassword/>} />
            <Route path="reset_password/:token" element={<ResetPassword/>} />
            <Route path="password_changed" element={<PasswordChanged/>} />
            
            {/* <Route path="/auth" render={() => <Redirect to="/auth/login" />} /> */}
            <Route path="*" element={<Navigate replace to="/auth/login" />} />
          </Routes>

          <FooterSmall absolute />
        </section>
      </main>
    </>
  );
  }
}
