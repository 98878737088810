import React, { useEffect, useState } from 'react';
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";

const Aligneurs = () => {
  return (
    <>
    <IndexNavbar fixed />
    <section className="bg-gradient-to-r from-blue-500 via-blue-700 to-blue-900 relative pt-16 items-center flex flex-col h-screen max-h-1000-px" id="index-cover">
      <div className="container max-w-xs text-center " style={{marginTop:"15rem"}}>
        <div className="bg-white bg-opacity-90 p-8 rounded-lg shadow-lg " style={{backgroundColor:"#ffffffcb", }}>
        {/* <img src={require("assets/img/SandClock.png").default} alt="aligneurs" className="w-64 h-64 mx-auto" /> */}
        <img
          id="SandClock"
          alt="Sand Clock"
          src={require("assets/img/SandClock.png")}
          className="rounded-lg mb-4 mx-auto" // Adjust the size of the image here
          style={{ width: "12%" }}
        />
        <h2 className="text-5xl font-semibold text-blueGray-800 mb-4">
          Coming Soon
        </h2>

        </div>
      </div>
    </section>
    <Footer />
    </>
  );
};

export default Aligneurs;