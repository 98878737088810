import React, { useState, useEffect } from "react";
import 'assets/styles/OrderConfirmation.css';
import 'assets/styles/hey.css'
import { useNavigate } from "react-router-dom";
import Users from "components/Cards/CardUser";
import { set } from "react-hook-form";

const AllUsers = ({ onReject }) => {
  const [activeLink, setActiveLink] = useState("");
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortedUsers, setSortedUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 4; // Number of orders to display per page
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch orders from your API and set them in state
    const fetchUsers = async () => {
      try {
        const response = await fetch("https://worlddigitaldental.com/wddapi/users");
        const data = await response.json();
        const approvedUsers = data.userss.filter(user => user.is_approved === true);
        setUsers(approvedUsers);
        setSortedUsers(approvedUsers); // Initially, set sortedOrders to be the same as orders
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    fetchUsers();
  }, []);


  const isLinkActive = (link) => {
    return activeLink === link;
  };

  const handleReject = (user) => {
    // Logic for rejecting the order
    console.log("User Deleted:", user);
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchTerm(searchTerm);

  const filteredUsers = users
  ? users.filter((user) => {
      const fullName = `${user.name} ${user.lastname}`.toLowerCase();
      const reverseFullName = `${user.lastname} ${user.name}`.toLowerCase();
      return fullName.includes(searchTerm.toLowerCase()) || reverseFullName.includes(searchTerm.toLowerCase());
    })
  : [];
    setSortedUsers(filteredUsers);
  };

  const handleSortByDate = () => {
    // Sort orders by date
    const sortedByDate = [...sortedUsers].sort((a, b) => new Date(b.date) - new Date(a.date));
    setSortedUsers(sortedByDate);
  };

  // Calculate total pages based on ordersPerPage
  const totalPages = Math.ceil(sortedUsers.length / usersPerPage);

  // Pagination functions
  const goToPage = (page) => {
    setCurrentPage(page);
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Calculate the range of orders to display for the current page
  const indexOfLastOrder = currentPage * usersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - usersPerPage;
  const currentOrders = sortedUsers.slice(indexOfFirstOrder, indexOfLastOrder);

  if (users === undefined) {
    // Loading state or return null
    return null;
  }

  return (
    <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
      <div className="flex justify-between mb-4">
        <input
          type="text"
          placeholder="Nom et Prénom d'utilisateur"
          value={searchTerm}
          onChange={handleSearch}
          className="px-2 py-1 border border-gray-300 rounded"
        />
        {/* <button
          onClick={handleSortByDate}
          className="bg-blue-500 text-white px-4 py-2 rounded"
        >
          Sort by Date
        </button> */}
      </div>
      {users == undefined || users.length === 0 ? (
        <p className="text-center text-2xl font-bold py-4">
          Il n'y a pas encore d'utilisateurs.
        </p>
      ) : (
        <>
          <div className="pb-4">
            {currentOrders.map(user => (
              <Users
                key={user.id}
                user={user}
                onReject={handleReject}
              />
            ))}
            <br/>
          </div>
          
          <div className="flex-grow" />
          <div className="fixed bottom-0 left-0 w-full bg-white p-8 flex justify-center">
          <button className="pagination-btn" onClick={prevPage} disabled={currentPage === 1}>
              Previous
            </button>
            {Array.from({ length: totalPages }, (_, i) => (
              <button
                key={i + 1}
                onClick={() => goToPage(i + 1)}
                disabled={currentPage === i + 1}
                className={`pagination-btn ${currentPage === i + 1 ? 'active' : ''}`}
              >
                {i + 1}
              </button>
            ))}
            <button className="pagination-btn" onClick={nextPage} disabled={currentPage === totalPages}>
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default AllUsers;
