// OrderConfirmationPage.js
import React, { useState, useEffect } from "react";
import 'assets/styles/OrderConfirmation.css';
import OrderItem from "components/Cards/CardOrder";
import { useNavigate } from "react-router-dom";
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';

const OrderConfirmation = ({ onConfirm, onReject }) => {
  const [activeLink, setActiveLink] = useState("");
  const [orders, setOrders] = useState([]);
  const navigate = useNavigate();

  const auth = useAuthUser();
  const id = auth.id;

  useEffect(() => {
    // Fetch orders from your API and set them in state
    const fetchOrders = async () => {
      try {
        const response = await fetch(`https://worlddigitaldental.com/wddapi/orders/${id}/waitingconfirmation`);
        const orderData = await response.json();
        setOrders(orderData.orders);

      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    fetchOrders();
  }, [id]); // Include id in the dependency array to re-fetch orders when id changes

  const isLinkActive = (link) => {
    return activeLink === link;
  };

  const handleConfirm = (order) => {
    // Logic for confirming the order
    // console.log("Order confirmed:", order);
  };

  const handleReject = async (orderId) => {
    // Logic for rejecting the order
    
    // console.log("Order rejected:", order);
    navigate(`/user/confirmation/rejection/${orderId}`);
  };

  if (!orders || orders.length === 0) {
    // Loading state or return null
    return (
      <>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
          <p className="text-center text-2xl font-bold py-4">
            Aucune commande à confirmée.
          </p>
      </div>
      </>
    );
  }

  return (
    <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
      <div className="grid grid-cols-3 order-grid">
        {orders.map((order, index) => (
          <OrderItem
            key={order.id}
            order={order}
            onConfirm={handleConfirm}
            onReject={handleReject}
          />
        ))}
      </div>
    </div>
  );
};

export default OrderConfirmation;
