import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const OrderRejectionPage = () => {
  const navigate = useNavigate();
  const [selectedRejectionReason, setSelectedRejectionReason] = useState('');
  const [otherReasonText, setOtherReasonText] = useState('');
  const { orderId } = useParams();

  const handleRejectionReasonChange = (reason) => {
    setSelectedRejectionReason(reason);
  };

  const handleOtherReasonChange = (event) => {
    setOtherReasonText(event.target.value);
  };

  const onSubmit = async () => {
    // Check if a rejection reason is selected
    if (selectedRejectionReason === 'Autre' && selectedRejectionReason && otherReasonText) {
      try {
        const statusUpdateResponse = await fetch(`https://worlddigitaldental.com/wddapi/orders/${orderId}/cancel`, {
          method: 'PUT',
        });
  
          if (statusUpdateResponse.ok) {
            console.log('Order status updated successfully!');
            // navigate("/user/rejected");
            const rejectionReason = {
              admin_rejection_reason: otherReasonText,
            };
            const response = await fetch(`https://worlddigitaldental.com/wddapi/orders/${orderId}/rejectionreason`, {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(rejectionReason),
            });
            if (response.ok) {
              console.log('Rejection reason updated successfully!');
              navigate("/admin/order_rejected");
            }
            else {
              console.error('Failed to update rejection reason.');
            }

          } else {
            console.error('Failed to update order status.');
          }
      }
      catch (error) {
        console.error('Error:', error);
      }
    }
    else if (selectedRejectionReason!== 'Autre' && selectedRejectionReason) {
      try {
        const statusUpdateResponse = await fetch(`https://worlddigitaldental.com/wddapi/orders/${orderId}/cancel`, {
          method: 'PUT',
        });
  
          if (statusUpdateResponse.ok) {
            console.log('Order status updated successfully!');
            // navigate("/user/rejected");
            const rejectionReason = {
              admin_rejection_reason: selectedRejectionReason,
            };
            const response = await fetch(`https://worlddigitaldental.com/wddapi/orders/${orderId}/rejectionreason`, {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(rejectionReason),
            });
            if (response.ok) {
              console.log('Rejection reason updated successfully!');
              navigate("/admin/order_rejected");
            }
            else {
              console.error('Failed to update rejection reason.');
            }

          } else {
            console.error('Failed to update order status.');
          }

      } catch (error) {
        console.error('Error:', error);
      }
    } else {
      console.error('Please select a rejection reason.');
    }
  };

  return (
    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0 mx-auto mt-10 p-8 bg-white">
      <h2 className="text-2xl font-bold mb-6 text-center text-red-600">Rejet de commande</h2>
      <div className="mb-6">
        <label className="block text-gray-700 text-sm font-bold mb-2">Raisons du rejet:</label>
        <div className="flex flex-col space-y-4">
          <button
            type="button"
            className="bg-blue-700 text-white font-bold py-2 px-4 rounded p-8 mg-4"
            onClick={() => handleRejectionReasonChange('Il manque des informations dans la commande')}
            style={{
              backgroundColor: selectedRejectionReason === 'Il manque des informations dans la commande' ? '#14939C' : '#ddd',
              color: selectedRejectionReason === 'Il manque des informations dans la commande' ? 'white' : '#555',
              margin: '5px',
            }}          >
            Il manque des informations dans la commande
          </button>
          {/* Add similar buttons for other rejection reasons */}
          <button
            type="button"
            className={`${
              selectedRejectionReason === 'Il y a un problème dans la commande'
            } hover:bg-blue-700 text-white font-bold py-2 px-4 rounded`}
            onClick={() => handleRejectionReasonChange('Il y a un problème dans la commande')}
            style={{
              backgroundColor: selectedRejectionReason === 'Il y a un problème dans la commande' ? '#14939C' : '#ddd',
              color: selectedRejectionReason === 'Il y a un problème dans la commande' ? 'white' : '#555',
              margin: '5px',
            }}
          >
            Il y a un problème dans la commande
          </button>
          {/* Add similar buttons for other rejection reasons */}
          <button
            type="button"
            className={`${
              selectedRejectionReason === 'Je veux annuler la commande'
            } hover:bg-blue-700 text-white font-bold py-2 px-4 rounded`}
            onClick={() => handleRejectionReasonChange('Je veux annuler la commande')}
            style={{
              backgroundColor: selectedRejectionReason === 'Je veux annuler la commande' ? '#14939C' : '#ddd',
              color: selectedRejectionReason === 'Je veux annuler la commande' ? 'white' : '#555',
              margin: '5px',
            }}
          >
            Je veux annuler la commande
          </button>
                    {/* Add similar buttons for other rejection reasons */}
          <button
            type="button"
            className={`${
              selectedRejectionReason === 'Autre'
            } hover:bg-blue-700 text-white font-bold py-2 px-4 rounded`}
            onClick={() => handleRejectionReasonChange('Autre')}
            style={{
              backgroundColor: selectedRejectionReason === 'Autre' ? '#14939C' : '#ddd',
              color: selectedRejectionReason === 'Autre' ? 'white' : '#555',
              margin: '5px',
            }}
          >
            Autre
          </button>
        </div>
        {selectedRejectionReason === 'Autre' && (
          <textarea
            name="otherReason"
            rows="8"
            className="w-full mt-2 px-3 py-2 border rounded focus:outline-none focus:border-blue-500"
            placeholder="Entrez le motif du refus..."
            value={otherReasonText}
            onChange={handleOtherReasonChange}
          />
        )}
      </div>

      <div className="flex justify-center">
        <button
          type="button"
          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
          onClick={onSubmit}
          disabled={!selectedRejectionReason}
          style={{
            backgroundColor: selectedRejectionReason ? '#4CAF50' : '#ddd',
            color: selectedRejectionReason ? 'white' : '#555',
            cursor: selectedRejectionReason ? 'pointer' : 'not-allowed',
          }}
        >
          Envoyer le rejet
        </button>
      </div>
    </div>
  );
};

export default OrderRejectionPage;
