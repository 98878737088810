import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";

const OrderItem = ({ order, onConfirm, onReject }) => {
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [pdfData, setPdfData] = useState([]);

  const showPDF = async () => {
    try {
      const pdfResponse = await fetch(`https://worlddigitaldental.com/wddapi/orders/${order.id}/firstpdf`);
      const pdfData = await pdfResponse.arrayBuffer();
  
      const pdfBlob = new Blob([pdfData], { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(pdfBlob);
  
      // Open the PDF in a new window
      window.open(pdfUrl, '_blank');
    } catch (error) {
      console.error("Error fetching or displaying PDF:", error);
    }
  };


  const handleCheckboxChange = () => {
    setIsCheckboxChecked(!isCheckboxChecked);
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleConfirm = () => {
    // Handle confirmation logic
    onConfirm(order);

    // Navigate to another page (replace '/confirmation-page' with the desired path)
    navigate(`/user/confirmation/amount/${order.id}`);
  };

  const handleReject = () => {
    // Handle rejection logic
    onReject(order.id);
  };

  const navigate = useNavigate();

  const buttonStyle = {
    backgroundColor: isCheckboxChecked ? "#4CAF50" : "#ddd",
    color: isCheckboxChecked ? "white" : "#555",
    cursor: isCheckboxChecked ? "pointer" : "not-allowed",
  };

  return (
    <div className="order-item border p-4 mb-4 flex flex-col items-start shadow-lg rounded-lg bg-blueGray-100 border-0">
      <h3 className="text-lg font-bold mb-2">
        {order.firstName} {order.lastName}
      </h3>
      <p>Type: {order.type}</p>
      <p>État: {order.status}</p>
      <p>Date: {order.date}</p>
      <button
        className="bg-blue-500 text-white px-4 py-2 mt-2 rounded"
        onClick={showPDF}
        style={{ backgroundColor: "#14939C" }}
      >
        Afficher le PDF
      </button>
      <div className="mt-3 flex items-center">
        <button onClick={handleModalOpen} className="ml-2 text-blue-500 underline">
        J'accepte les Conditions Générales
        </button>
        <label className="mr-2" htmlFor={`confirmCheckbox_${order.id}`}>
          {/* j'accepte les conditions générales et la politique de confidentialité */}
        </label>
        <input
          type="checkbox"
          id={`confirmCheckbox_${order.id}`}
          checked={isCheckboxChecked}
          onChange={handleCheckboxChange}
        />
      </div>
      <div className="mt-2 flex justify-end w-full">
        <button
          className="bg-green-500 text-white px-4 py-2 mr-2 rounded"
          onClick={handleConfirm}
          disabled={!isCheckboxChecked}
          style={buttonStyle}
        >
          Confirmer
        </button>
        <button
          className="bg-red-500 text-white px-4 py-2 mr-2 rounded"
          onClick={handleReject}
        >
          Rejeter
        </button>
      </div>

      {/* Modal for displaying policies */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleModalClose}
        contentLabel="Policies Modal"
        style={{
          content: {
            width: "60%", // Set the width to your desired size (e.g., 60%)
            height: "60vh", // Set the height to your desired size (e.g., 60% of the viewport height)
            margin: "auto", // Center the modal
          },
        }}
      >
        <div>
          <div className="flex justify-end">
            <button onClick={handleModalClose} className="text-xl">
              <span>&times;</span>
            </button>
          </div>
          <h2 style={{ fontWeight: "bold", fontSize: "1.2rem" }}>Conditions générales de validation : </h2>
          <p style = {{padding: "5px"}}>
          Votre planification étant faite selon votre commande, vos directives ainsi que vos remarques, vous en validez la qualité et vous acceptez les points suivants : 
          </p>
          <p style = {{padding: "5px"}}>
          Je confirme que je prendrai connaissance du protocole de forage avant l’intervention chirurgicale et que l’information fournie correspond à l’intervention chirurgicale prévue.
          </p>
          <p style = {{padding: "5px"}}>
          J’accepte que WDD n’assume aucune responsabilité pour les dommages ou blessures résultant de la planification et du traitement.
          </p>
          <p style = {{padding: "5px"}}>
          S’il y a lieu, je vérifierai avant l’intervention chirurgicale la stabilité de l’ajustement du guide chirurgical et sa capacité à remplir la fonction prévue. 
          </p>

          <h2 style={{ fontWeight: "bold", fontSize: "1.2rem" }}>Validation de la planification :  </h2>
          <p style = {{padding: "5px"}}>
          Je suis satisfait des aspects médicaux et cliniques de la planification proposée et je les valide. 
          </p>
          <p style = {{padding: "5px"}}>
          S’il y a lieu, je confirme que le nerf mandibulaire est correctement défini.
          </p>
          <p style = {{padding: "5px"}}>
          Je confirme que les données de numérisation utilisées pour le scan CBCT et les scans de surface sont à jour, et que leur qualité et leurs affichage sont suffisants pour la situation de la planification et la solution finale prévue.
          </p>
          < p style = {{padding: "5px"}}>
          J’accepte le fait que WDD n’effectue aucune vérification médicale ou clinique de la planification.
          </p>

          <h2 style={{ fontWeight: "bold", fontSize: "1.2rem" }}>Validation du guide chirurgical: </h2>
          <p style = {{padding: "5px"}}>
          Je confirme que la conception du guide chirurgical a été effectuée correctement, qu’elle assure un ajustement stable et ferme lors de l’intervention chirurgicale et qu’elle remplit les fonctions prévues.
          </p>
          <p style = {{padding: "5px"}}>
          Je suis pleinement conscient des contraintes techniques liées à la fabrication du guide chirurgical.
          </p>
          <p style = {{padding: "5px"}}>
          S’il y a lieu, je confirme que je vérifierai la qualité et le fonctionnement du guide chirurgical avant son utilisation.
          </p>

        </div>
      </Modal>
    </div>
  );
};

export default OrderItem;
