import React from "react";
import CardNouvelleCommande from "components/Cards/CardNouvelleCommande";

// components

export default function GuideChirurgical() {
  return (
    <>
      <CardNouvelleCommande></CardNouvelleCommande>
    </>
  );
}
