import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { saveAs } from 'file-saver';
import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';


const OrderPaymentPage = () => {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [pdfData, setPdfData] = useState(null);

  useEffect(() => {
    // Fetch PDF data from your API endpoint
    const fetchPdfData = async () => {
      try {

          const pdfResponse = await fetch(`https://worlddigitaldental.com/wddapi/orders/${orderId}/payment_pdf`);
          const pdfData = await pdfResponse.arrayBuffer();
      
          const pdfBlob = new Blob([pdfData], { type: 'application/pdf' });
          const pdfUrl = URL.createObjectURL(pdfBlob);
      
          // Open the PDF in a new window
          setPdfData(pdfUrl);

      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchPdfData();
  }, []);

  const handleCheckboxChange = () => {
    setIsCheckboxChecked(!isCheckboxChecked);
  };

  const handleAccept = () => {
    if (isCheckboxChecked) {
      // Handle payment logic
      console.log('Payment accepted. Redirecting...');
      // You can redirect to a success page or perform other actions here
      navigate(`/user/payment/${orderId}`);
    } else {
      console.error('Please accept the amount before proceeding.');
    }
  };

  const styles = StyleSheet.create({
    container: {
      border: '2px solid #000000',
      borderRadius: 8,
      padding: 20,
      maxWidth: 800, // Set the maximum width of the container
      minWidth: 800,
      minHeight: 500,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    page: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    text: {
      fontSize: 20,
      marginBottom: 10,
    },
    checkIcon: {
      color: '#27ae60',
      fontSize: 50,
      marginBottom: 10,
    },
  });


  return (
    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
      <div className="flex flex-col items-center">
        <br />
        <h2 className="text-3xl font-bold mb-6 text-center text-red-600">Détails de paiement</h2>
        <div style={{ width: '90%', height: '500px', overflow: 'auto', borderRadius: 10 }}>
          {/* Display the PDF using an iframe */}
          {pdfData && <iframe title="PDF Viewer" src={pdfData} width="100%" height="94%" />}
        </div>
        <div className="flex items-center mb-3">
          <label className="mr-2" htmlFor="acceptCheckbox">
          J'accepte le montant et je peux le payer
          </label>
          <input
            type="checkbox"
            id="acceptCheckbox"
            checked={isCheckboxChecked}
            onChange={handleCheckboxChange}
          />
        </div>
        <br />
        <button
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
          onClick={handleAccept}
          disabled={!isCheckboxChecked}
          style={{
            backgroundColor: isCheckboxChecked ? '#4CAF50' : '#ddd',
            color: isCheckboxChecked ? 'white' : '#555',
            cursor: isCheckboxChecked ? 'pointer' : 'not-allowed',
          }}
        >
          Accepter et payer
        </button>
        <br />
      </div>
    </div>
  );
};

export default OrderPaymentPage;
