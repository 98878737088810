import React from "react";

const OrderRejectionConfirmation = () => {
  return (
    <>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
      <div className="flex flex-col items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="red"
          className="w-16 h-16 mb-4"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          ></path>
        </svg>
        <h2 className="text-2xl font-bold text-red-600">Commande rejetée!</h2>
        <p className="text-gray-500">
          La commande a été rejetée.
        </p>
      </div>
    </div>
    </>
  );
};

export default OrderRejectionConfirmation;
