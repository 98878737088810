// OrderConfirmationPage.js
import React, { useState, useEffect } from "react";
import 'assets/styles/OrderConfirmation.css';
import { useNavigate } from "react-router-dom";
import OrderCloutreCard from "components/Cards/CardOrderCloturee";

const OrderCloture = ({ onConfirm, onReject }) => {

  const [activeLink, setActiveLink] = useState("");
  const [orders, setOrders] = useState([]); // Just for now. we need to change orders1 ==> orders in the dynamic part
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch orders from your API and set them in state
    const fetchOrders = async () => {
      try {
        const response = await fetch("https://worlddigitaldental.com/wddapi/orders/complete");
        const data = await response.json();
        setOrders(data.orders);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    fetchOrders();
  }, []); // Run this effect only once on component mount


  const handleDelete = (order) => {
    // Logic for rejecting the order
    console.log("Order Deleted:", order);
    navigate("/admin/supprimer")
  };

  if (!orders || orders.length === 0) {
    // Loading state or return null
    return (
      <>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
          <p className="text-center text-2xl font-bold py-4">
            Aucune commande cloturée.
          </p>
      </div>
      </>
    );
  }


  return (
    <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
      <div className="grid grid-cols-3 order-grid">
        {orders.map((order) => (
          <OrderCloutreCard
          key={order.id}
          order={order}
          onReject={handleDelete}
        />
        ))}
      </div>
    </div>
  );
};

export default OrderCloture;
