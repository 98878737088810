import React from "react";

const PasswordChanged = () => {
  return (
    <>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
      <div className="container mx-auto px-8 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-8/12 px-20">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-12 shadow-lg rounded-lg bg-blueGray-200 border-0">
              <div className="rounded-t mb-0 px-6 py-6">
              <h2 className="text-2xl text-center font-bold mb-4">Votre mot de passe a été modifié avec succès</h2>
                <p className="text-gray-600">
                Votre mot de passe a été modifié avec succès. Si vous n'avez pas effectué cette modification, veuillez contacter l'administrateur.
                </p>
            </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PasswordChanged;
