import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { StyleSheet } from '@react-pdf/renderer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faUpload } from '@fortawesome/free-solid-svg-icons';

const ShowDetails = () => {
  const navigate = useNavigate();
  const { orderId } = useParams(); // Assuming you have the order ID in the route params

  const [selectedRejectionReason, setSelectedRejectionReason] = useState('');
  const [otherReasonText, setOtherReasonText] = useState('');

  const [pdfData, setPdfData] = useState(null);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');

  const [firstPDF, setFirstPDF] = useState(null);
  const [firstPDFName, setFirstPDFName] = useState('');

  
  // const handleRejectionReasonChange = (reason) => {
  //   setSelectedRejectionReason(reason);
  // };

  // const handleOtherReasonChange = (event) => {
  //   setOtherReasonText(event.target.value);
  // };

  const handleFileChange = (event) => {
    const uploadedFile = event.target.files[0];
    setFile(uploadedFile);
    
    // Extract the file name and set it in the state
    const fileName = uploadedFile ? uploadedFile.name : '';
    setFileName(fileName);
  };

  const handleFirstPDFChange = (event) => {
    const uploadedFile = event.target.files[0];
    setFirstPDF(uploadedFile);

    // Extract the file name and set it in the state
    const fileName = uploadedFile ? uploadedFile.name : '';
    setFirstPDFName(fileName);
  }


  useEffect(() => {
    // Fetch PDF data and order details from your API endpoint based on orderId
    const fetchOrderDetails = async () => {
      try {

        const pdfResponse = await fetch(`https://worlddigitaldental.com/wddapi/orders/${orderId}/pdf`);
        const pdfData = await pdfResponse.arrayBuffer();
    
        const pdfBlob = new Blob([pdfData], { type: 'application/pdf' });
        const pdfUrl = URL.createObjectURL(pdfBlob);
    
        // Open the PDF in a new window
        setPdfData(pdfUrl);

    } catch (error) {
      console.error('Error:', error);
    }
    };

    fetchOrderDetails();
  }, [orderId]);

  // const handleCheckboxChange = (option) => {
  //   if (option === 'other') {
  //     setShowOtherInput((prevShowOtherInput) => !prevShowOtherInput);
  //   } else {
  //     setValidationOptions((prevOptions) => ({
  //       ...prevOptions,
  //       [option]: !prevOptions[option],
  //     }));
  //   }
  // };  

  const handleAccept = async (file) => {

    try {
      // Check if a PDF is selected
      if (file && firstPDF) {
        const formData = new FormData();
        formData.append('file', file);

        const formData2 = new FormData();
        formData2.append('file', firstPDF);
  
        // Replace 'YOUR_UPLOAD_API_ENDPOINT' with your actual API endpoint for PDF upload
        const uploadResponse = await fetch(`https://worlddigitaldental.com/wddapi/orders/${orderId}/paymentpdf`, {
          method: 'PUT',
          body: formData,
        });

        const uploadResponse2 = await fetch(`https://worlddigitaldental.com/wddapi/orders/${orderId}/firstpdf`, {
          method: 'PUT',
          body: formData2,
        });
  
        if (uploadResponse.ok && uploadResponse2.ok) {
          console.log('PDF uploaded successfully');
  
          // Now, update the order status
          const statusUpdateResponse = await fetch(`https://worlddigitaldental.com/wddapi/orders/${orderId}/aconfirme`, {
            method: 'PUT',
          });
  
          if (statusUpdateResponse.ok) {
            console.log('Order status updated successfully!');
            navigate('/admin/pdf_envoyee');
            // Handle success (e.g., show a success message)
          } else {
            console.error('Failed to update order status.');
            // Handle failure in updating order status
          }
        } else {
          console.error('Failed to upload PDF');
          // Handle failure in PDF upload
        }
      } else {
        console.error('No PDF selected');
        // Handle the case where no PDF is selected (show an error message, for example)
      }
    } catch (error) {
      console.error('An error occurred:', error);
      // Handle other errors
    }
  };
  
  

  const handleReject = () => {
    navigate(`/admin/rejeter/${orderId}`);
    // Check if a rejection reason is selected
    // if (selectedRejectionReason === 'Other') {
    //   try {
    //     const statusUpdateResponse = await fetch(`/orders/${orderId}/cancel`, {
    //       method: 'PUT',
    //     });
  
    //       if (statusUpdateResponse.ok) {
    //         console.log('Order status updated successfully!');
    //         // navigate("/user/rejected");
    //         const rejectionReason = {
    //           admin_rejection_reason: otherReasonText,
    //         };
    //         const response = await fetch(`/orders/${orderId}/rejectionreason`, {
    //           method: 'PUT',
    //           headers: {
    //             'Content-Type': 'application/json',
    //           },
    //           body: JSON.stringify(rejectionReason),
    //         });
    //         if (response.ok) {
    //           console.log('Rejection reason updated successfully!');
    //           navigate("/user/rejected");
    //         }
    //         else {
    //           console.error('Failed to update rejection reason.');
    //         }

    //       } else {
    //         console.error('Failed to update order status.');
    //       }
    //   }
    //   catch (error) {
    //     console.error('Error:', error);
    //   }
    // }
    // else if (selectedRejectionReason!== 'Other' && selectedRejectionReason) {
    //   try {
    //     const statusUpdateResponse = await fetch(`/orders/${orderId}/cancel`, {
    //       method: 'PUT',
    //     });
  
    //       if (statusUpdateResponse.ok) {
    //         console.log('Order status updated successfully!');
    //         // navigate("/user/rejected");
    //         const rejectionReason = {
    //           admin_rejection_reason: selectedRejectionReason,
    //         };
    //         const response = await fetch(`/orders/${orderId}/rejectionreason`, {
    //           method: 'PUT',
    //           headers: {
    //             'Content-Type': 'application/json',
    //           },
    //           body: JSON.stringify(rejectionReason),
    //         });
    //         if (response.ok) {
    //           console.log('Rejection reason updated successfully!');
    //           navigate("/user/rejected");
    //         }
    //         else {
    //           console.error('Failed to update rejection reason.');
    //         }

    //       } else {
    //         console.error('Failed to update order status.');
    //       }

    //   } catch (error) {
    //     console.error('Error:', error);
    //   }
    // } else {
    //   console.error('Please select a rejection reason.');
    // }
  };

  

  const styles = StyleSheet.create({
    container: {
      borderRadius: 8,
      padding: 20,
      maxWidth: 600,
      display: 'flex',
      flexDirection: 'column',
    },
    page: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    text: {
      fontSize: 20,
      marginBottom: 10,
    },
    checkboxLabel: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 10,
    },
    checkbox: {
      marginRight: 10,
    },
    buttonsContainer: {
      justifyContent: 'space-between',
      marginTop: 20,
      flexDirection: 'column',
      alignItems: 'center',
    },
    acceptButton: {
      backgroundColor: file && firstPDF ? '#2ecc71' : '#ddd',
      color: file && firstPDF ? 'white' : '#555',
      cursor: file ? 'pointer' : 'not-allowed',
      padding: '10px 20px',
      borderRadius: 5,
    },
    rejectButton: {
      backgroundColor: '#ff6961',
      color: 'white',
      cursor: 'pointer',
      padding: '10px 20px',
      borderRadius: 5,
      cursor: 'pointer',
      marginLeft: 20,
    },
  });

  return (
    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0 p-8">

    <div className="flex flex-col">
      <h2 className="text-3xl font-bold mb-6 text-red-600 text-center">Order Details</h2>
        <div>
          <div style={{ width: '100%', height: '500px', overflow: 'auto', borderRadius: 10 }}>
          {/* Display the PDF using an iframe */}
          {pdfData && <iframe title="PDF Viewer" src={pdfData} width="100%" height="94%" />}
          </div>
          {/* File Upload */}
          <div className="mt-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            <FontAwesomeIcon icon={faFilePdf} className="mr-2" />
            Joindre le PDF de paiement:
          </label>
          <div className="flex items-center mb-4 border-0 rounded-lg" style={{backgroundColor:"#14939C"}}>
          <label
            htmlFor="pdf"
            className="cursor-pointer bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            <FontAwesomeIcon icon={faUpload} className="mr-2" />
            Select PDF
          </label>
            <input
              type="file"
              accept=".pdf"
              onChange={handleFileChange}
            />
          </div>
          {file && (
          <p className="text-gray-700">
            Selected PDF: <span className="font-bold">{fileName}</span>
          </p>
        )}
          </div>


          {/* File Upload */}
          <div className="mt-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            <FontAwesomeIcon icon={faFilePdf} className="mr-2" />
            Joindre le PDF de la commande:
          </label>
          <div className="flex items-center mb-4 border-0 rounded-lg" style={{backgroundColor:"#14939C"}}>
          <label
            htmlFor="pdf"
            className="cursor-pointer bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            <FontAwesomeIcon icon={faUpload} className="mr-2" />
            Select PDF
          </label>
            <input
              type="file"
              accept=".pdf"
              onChange={handleFirstPDFChange}
            />
          </div>
          {firstPDF && (
          <p className="text-gray-700">
            Selected PDF: <span className="font-bold">{firstPDFName}</span>
          </p>
        )}
          </div>
          {/* <div> */}
          {/* <div style={styles.checkboxLabel}>
          <button
            type="button"
            className="bg-blue-700 text-white font-bold py-2 px-4 rounded p-8 mg-4 flex items-center"
            onClick={() => handleRejectionReasonChange('MissingInformation')}
            style={{
              backgroundColor: selectedRejectionReason === 'MissingInformation' ? '#14939C' : '#ddd',
              color: selectedRejectionReason === 'MissingInformation' ? 'white' : '#555',
              margin: '5px',
              width: '30rem',
            }}          >
            The order is missing information
          </button>
          </div>
          <div style={styles.checkboxLabel}>
          <button
            type="button"
            className={`${
              selectedRejectionReason === 'ProblemSpecifications'
            } hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center`}
            onClick={() => handleRejectionReasonChange('ProblemSpecifications')}
            style={{
              backgroundColor: selectedRejectionReason === 'ProblemSpecifications' ? '#14939C' : '#ddd',
              color: selectedRejectionReason === 'ProblemSpecifications' ? 'white' : '#555',
              margin: '5px',
              width: '30rem'
            }}
          >
            There is problem with the specifications
          </button>
          </div>
          <div style={styles.checkboxLabel}>
          <button
            type="button"
            className={`${
              selectedRejectionReason === 'CancelCommand'
            } hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center`}
            onClick={() => handleRejectionReasonChange('CancelCommand')}
            style={{
              backgroundColor: selectedRejectionReason === 'CancelCommand' ? '#14939C' : '#ddd',
              color: selectedRejectionReason === 'CancelCommand' ? 'white' : '#555',
              margin: '5px',
              width: '30rem'
            }}
          >
            I want to cancel the command
          </button>
          </div>

          <div style={styles.checkboxLabel}>
          <button
            type="button"
            className={`${
              selectedRejectionReason === 'Other'
            } hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center`}
            onClick={() => handleRejectionReasonChange('Other')}
            style={{
              backgroundColor: selectedRejectionReason === 'Other' ? '#14939C' : '#ddd',
              color: selectedRejectionReason === 'Other' ? 'white' : '#555',
              margin: '5px',
              width: '30rem'
            }}
          >
            Other
          </button>
          </div>
          {selectedRejectionReason === 'Other' && (
          <textarea
            name="otherReason"
            rows="8"
            className="w-full mt-2 px-3 py-2 border rounded focus:outline-none focus:border-blue-500"
            placeholder="Enter the reason for rejection..."
            value={otherReasonText}
            onChange={handleOtherReasonChange}
          />
        )}
        </div> */}
        {/* Accept and reject buttons */}
        <br/>
        <div style={styles.buttonsContainer}>
          <button
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
              onClick={() => handleAccept(file, firstPDF)}
              style={styles.acceptButton}
              disabled={!file || !firstPDF}
            >
            Accepter
          </button>
          <button
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
            onClick={handleReject}
            style={styles.rejectButton}
          >
            Rejeter
          </button>
        </div>
      </div>
    </div>
    </div>
  );
};

export default ShowDetails;
