import React,{ useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ErrorMessage from "components/Cards/ErrorModal";
import Modal from "react-modal";


export default function Register() {

    const [formData, setFormData] = useState({
      username: "",
      name : "",
      lastname : "",
      email: "",
      gender : "",
      user_type : "",
      fix_number : "0",
      mobile_number : "",
      adress : "",
      ville : "",
      pays : "",
      matricule : "",
      password_hash : "",
      passwordConfirmation: "",
    });
  
    const [error, setError] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [email, setEmail] = useState("");
    const [isValidEmail, setIsValidEmail] = useState(true);
    const navigate = useNavigate();
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const [passwordsMatch, setPasswordsMatch] = useState(true); // new state for password matching

    const [isModalOpenconf, setIsModalOpenconf] = useState(false);

    const handleModalOpenConf = () => {
      setIsModalOpenconf(true);
    };

    const handleModalCloseconf = () => {
      setIsModalOpenconf(false);
    };

  
    const handleModalClose = () => {
      setShowError(false);
    };
  
    const handleEmailChange = (e) => {
      const enteredEmail = e.target.value;
      setEmail(enteredEmail);
  
      // Regular expression for a simple email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      setIsValidEmail(emailRegex.test(enteredEmail));

      // Update the state
      setFormData({
        ...formData,
        email: enteredEmail,
      });
    };
  
  
    const handleChange = (e) => {
      if (e.target.type === "radio") {
        // For radio buttons, set the value directly
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
      } 
      
      else if (e.target.name === 'passwordConfirmation') {
        setFormData({
          ...formData,
          passwordConfirmation: e.target.value,
        });
      }  
      
      else {
        // For other input types, use the name and value attributes
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
      }
    };

    const handleCheckboxChange = (e) => {
  
      if (e.target.type === 'checkbox') {
        setIsCheckboxChecked(e.target.checked);
      }
    };

        const handleSubmit = async () => {
      try {

        if (formData.password !== formData.passwordConfirmation) {
          setErrorMessage("Les mots de passe ne correspondent pas");
          setPasswordsMatch(false);
        }
        
        else {
            const processUserRequestResponse = await fetch("https://worlddigitaldental.com/wddapi/users", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                username: formData.username,
                name : formData.nom,
                lastname : formData.prenom,
                email: formData.email,
                gender : formData.sexe,
                user_type : formData.occupation,
                fix_number : formData.tel_fixe,
                mobile_number : formData.mobile,
                adress : formData.adress,
                ville : formData.ville,
                pays : formData.pays,
                matricule : formData.matricule,
                password_hash : formData.password,
                
                // action: "register", // or "accept" if you prefer
              }),
    
            });

            setPasswordsMatch(true);
    
            const processUserRequestData = await processUserRequestResponse.json();
    
            if (processUserRequestData.success) {
              setError("");
              setSuccessMessage(processUserRequestData.error);
              const sendmessage = await axios.post('https://worlddigitaldental.com/wddapi/message/send_email_registration', {
                name: formData.name,
                email: formData.email,
              });
              navigate("/auth/waiting");
            }
          
            else {
              setErrorMessage(processUserRequestData.error);
              setShowError(true);
            }  
        }

      } catch (error) {
        console.error('Registration or update failed', error);
        setErrorMessage("L'enregistrement ou la mise à jour a échoué. Veuillez réessayer.");
        setShowError(true);
      }
    };
  
  

  return (
    <>
<div className="container mx-auto px-4 h-full">
  <div className="flex content-center items-center justify-center h-full">
    <div className="w-full lg:w-12/12 px-8">
      <div className="relative grid grid-cols-2 gap-4 min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
        <div className="rounded-t mb-0 px-6 py-6 col-span-2">
          <div className="text-center mb-3">
                  <h6 className="text-blueGray-500 text-sm font-bold">
                    Connectez-vous à
                  </h6>
                </div>
                <img 
                src={require("assets/img/WDD.png")} width={200} 
                className="mx-auto h-16 w-auto"
                />

                <hr className="mt-6 border-b-1 border-blueGray-300" />
              </div>
              <div className="text-blueGray-400 text-center mb-3 font-bold">
                  <small>Créer un nouveau compte</small>
                </div>

              
              <div className="flex flex-wrap">


                {/* Left Group */}
                <div className="flex-auto px-4 lg:px-10 py-10 pt-0">                
                <form>
                  <div className="grid grid-cols-2 gap-4">
                    <div className="relative mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="nom"
                      >
                        Nom :
                      </label>
                      <input
                        type="text"
                        id="nom"
                        name="nom"
                        value={formData.nom}
                        onChange={handleChange}
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Nom"
                      />
                    </div>

                    <div className="relative mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="prenom"
                      >
                        Prénom :
                      </label>
                      <input
                        type="text"
                        id="prenom"
                        name="prenom"
                        value={formData.prenom}
                        onChange={handleChange}
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Prénom"
                      />
                    </div>

                    {/* Sexe Radio Buttons */}
                    <div className="relative mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="sexe"
                      >
                        Sexe :
                      </label>
                      <div>
                        <label htmlFor="sexe-m" className="mr-2">
                          Homme
                          <input
                            type="radio"
                            id="sexe-m"
                            name="sexe"
                            value="M"
                            checked={formData.sexe === "M"}
                            onChange={handleChange}
                          />
                        </label>
                        <label htmlFor="sexe-f">
                          Femme
                          <input
                            type="radio"
                            id="sexe-f"
                            name="sexe"
                            value="F"
                            checked={formData.sexe === "F"}
                            onChange={handleChange}
                          />
                        </label>
                      </div>
                    </div>

                    {/* Occupation Select */}
                    <div className="relative mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="occupation"
                      >
                        Vous êtes :
                      </label>
                      <select
                        id="occupation"
                        name="occupation"
                        value={formData.occupation}
                        onChange={handleChange}
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      >
                        <option value="" >
                          Sélectionnez votre occupation
                        </option>
                        <option value="Dentiste">Dentiste</option>
                        <option value="Orthodontiste">Orthodontiste</option>
                        <option value="Prothésiste"> Prothésiste </option>
                      </select>
                    </div>


                  </div>

                  
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="email"
                    >
                      Email :
                    </label>
                    <input
                      type="text"
                      id="email"
                      name="email"
                      defaultValue={formData.email}
                      onChange={handleEmailChange}
                      className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${
                        !isValidEmail && "border-red-500" 
                      }`}
                      placeholder="Email"
                    />
                  {!isValidEmail && (
                  <p className="text-red-500 text-xs mt-1">Invalid email format</p>
                  )}
                  </div>


                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="tel-fixe"
                    >
                      Numéro de téléphone fixe  (facultatif)
                    </label>
                    <input
                      type="text"
                      id="tel-fixe"
                      name="tel-fixe"
                      value={formData.tel_fixe || ""}  // Ensure formData.tel_fixe is not undefined
                      onChange={(e) => setFormData({ ...formData, tel_fixe: e.target.value })}
                      pattern="[0-9]{10}"  // Restriction: 10 digits
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Numéro de téléphone fixe"
                    />
                    {/* <small className="text-red-500">
                      facultatif
                    </small> */}
                  </div>
                
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="mobile"
                    >
                      Mobile* :
                    </label>
                    <PhoneInput
                      type="text"
                      country={"tn"}
                      enableSearch={true}
                      value={formData.mobile || ""}  // Ensure formData.mobile is not undefined
                      onChange={(phone) => setFormData({ ...formData, mobile: phone })}
                      // value={formData.mobile}
                      // onChange={handleChange}
                      // onChange={(phone) => setPhone(phone)}
                    />
                  </div>
            
                  </form>
                </div>  
                




                  {/* Right Group */}
                  <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="adresse"
                    >
                      Adresse:
                    </label>
                    <input
                      type="text"
                      id="adresse"
                      name="adresse"
                      value={formData.adress || ""}  // Ensure formData.tel_fixe is not undefined
                      onChange={(e) => setFormData({ ...formData, adress: e.target.value })}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Adresse du cabinet"
                    />
                  </div>
                
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="ville"
                    >
                      Ville :
                    </label>
                    <input
                      type="text"
                      id="ville"
                      name="ville"
                      value={formData.ville || ""}  // Ensure formData.tel_fixe is not undefined
                      onChange={(e) => setFormData({ ...formData, ville: e.target.value })}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Ville"
                    />
                  </div>
                
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="pays"
                    >
                      Pays :
                    </label>
                    <input
                      type="text"
                      id="pays"
                      name="pays"
                      value={formData.pays || ""}  // Ensure formData.tel_fixe is not undefined
                      onChange={(e) => setFormData({ ...formData, pays: e.target.value })}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Pays"
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="matricule"
                    >
                      Matricule fiscal ou registre d’entreprise :
                    </label>
                    <input
                      type="text"
                      id="matricule"
                      name="matricule"
                      value={formData.matricule || ""}  // Ensure formData.tel_fixe is not undefined
                      onChange={(e) => setFormData({ ...formData, matricule: e.target.value })}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="……./. /. /000"
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="username"
                    >
                      Nom d’utilisateur :
                    </label>
                    <input
                      type="text"
                      id="username"
                      name="username"
                      value={formData.username || ""}  // Ensure formData.tel_fixe is not undefined
                      onChange={(e) => setFormData({ ...formData, username: e.target.value })}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Nom d’utilisateur"
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="password"
                    >
                      Mot de passe :
                    </label>
                    <input
                      type="password"
                      id="password"
                      name="password"
                      value={formData.password || ""}  // Ensure formData.tel_fixe is not undefined
                      onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Mot de passe"
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="confirm-password"
                    >
                      Confirmation du mot de passe :
                    </label>
                    <input
                      type="password"
                      id="confirm-password"
                      name="passwordConfirmation"
                      value={formData.passwordConfirmation || ''}
                      onChange={handleChange}
                      className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${
                        !passwordsMatch && 'border-red-500'
                      }`}
                      placeholder="Confirmation du mot de passe"
                    />
                    {!passwordsMatch && (
                      <p className="text-red-500 text-xs mt-1">Les mots de passe ne correspondent pas</p>
                    )}
                  </div>
                  <div>
                    <label className="inline-flex items-center cursor-pointer">
                      <input
                        id="customCheckLogin"
                        type="checkbox"
                        className="form-checkbox border-0 rounded ml-1 w-5 h-5 ease-linear transition-all duration-150"
                        onChange={handleCheckboxChange}                      
                      />
                      <span className="ml-2 text-sm font-semibold text-blueGray-600">
                      Je suis d'accord avec la{" "}
                        <a
                          href="#pablo"
                          className="text-lightBlue-500"
                          onClick={handleModalOpenConf}
                        >
                          Politique de confidentialité
                        </a>
                      </span>
                    </label>
                  </div>

                  <div className="text-center mt-6">
                    <button
                      className={`bg-${isCheckboxChecked ? 'Wdd' : 'Gray'} text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150`}
                      type="button"
                      onClick={handleSubmit}
                      disabled={!isCheckboxChecked}
                    >
                      Créer un compte
                    </button>
                  </div>

                  <>
                    {/* ... (your existing code) */}
                     {showError && (
                      <ErrorMessage message={errorMessage} onClose={handleModalClose} />
                    )}
                  </>
               
                </div>
              </div>
            </div>
          </div>
        </div>

              {/* Modal for displaying policies */}
      <Modal
        isOpen={isModalOpenconf}
        onRequestClose={handleModalCloseconf}
        contentLabel="Policies Modal"
        style={{
          content: {
            width: "60%", // Set the width to your desired size (e.g., 60%)
            height: "60vh", // Set the height to your desired size (e.g., 60% of the viewport height)
            margin: "auto", // Center the modal
          },
        }}
      >
        <div>
          <div className="flex justify-end">
            <button onClick={handleModalCloseconf} className="text-xl">
              <span>&times;</span>
            </button>
          </div>
          <div>
  <h2 style={{ fontWeight: "bold", fontSize: "1.2rem" }}>Politique de confidentialité</h2>
  <p style={{ padding: "5px" }}>
    Nous accordons une grande importance à la confidentialité de vos données personnelles. Cette politique de confidentialité décrit comment nous recueillons, utilisons et partageons vos données lorsque vous utilisez nos services ou que vous interagissez avec notre site web.
  </p>
  <h3 style={{ fontWeight: "bold", fontSize: "1.1rem", marginTop: "10px" }}>Collecte et utilisation des informations</h3>
  <p style={{ padding: "5px" }}>
    Nous recueillons divers types d'informations à des fins diverses afin de fournir et d'améliorer nos services. Cela peut inclure des informations fournies directement par vous-même, telles que votre nom, votre adresse e-mail, votre adresse postale, etc. Nous utilisons ces informations pour traiter vos commandes, communiquer avec vous et vous fournir un service clientèle efficace.
  </p>
  <h3 style={{ fontWeight: "bold", fontSize: "1.1rem", marginTop: "10px" }}>Partage des informations</h3>
  <p style={{ padding: "5px" }}>
    Nous ne vendons, ne louons ni ne partageons vos informations personnelles avec des tiers à des fins commerciales. Cependant, nous pouvons partager vos informations avec des prestataires de services tiers qui nous aident à exploiter notre site web ou à fournir nos services.
  </p>
  <h3 style={{ fontWeight: "bold", fontSize: "1.1rem", marginTop: "10px" }}>Sécurité des données</h3>
  <p style={{ padding: "5px" }}>
    Nous prenons des mesures de sécurité raisonnables pour protéger vos informations personnelles contre la perte, l'utilisation abusive et l'accès non autorisé. Cependant, veuillez noter qu'aucune méthode de transmission sur Internet ou de stockage électronique n'est totalement sécurisée, et nous ne pouvons garantir la sécurité absolue de vos données.
  </p>
  <h3 style={{ fontWeight: "bold", fontSize: "1.1rem", marginTop: "10px" }}>Consentement</h3>
  <p style={{ padding: "5px" }}>
    En utilisant nos services ou en interagissant avec notre site web, vous consentez à notre collecte, à notre utilisation et à notre partage de vos informations personnelles conformément à cette politique de confidentialité.
  </p>
  <h3 style={{ fontWeight: "bold", fontSize: "1.1rem", marginTop: "10px" }}>Modification de la politique de confidentialité</h3>
  <p style={{ padding: "5px" }}>
    Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment, donc veuillez la consulter périodiquement. Les modifications prendront effet dès leur publication sur cette page.
  </p>
</div>


        </div>
      </Modal>

      </div>
    </>
  );
}
