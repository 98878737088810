import React from 'react';

function MessageComponent() {
  return (
    <>
    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
    <div className="rounded-t bg-white mb-0 px-6 py-6">
    <div className="message-component">
        <p style={{  fontSize: "16px", fontWeight: "bold" }}>INSTRUCTIONS:</p>
        <br></br>
        <p style={{  fontSize: "17px" }} >Pour optimiser le processus de commande via notre plateforme, il vous suffit de remplir le formulaire fourni et de télécharger vos fichiers STL et DICOM via WeTransfer. Pour une efficacité optimale, il est recommandé d'utiliser une connexion internet robuste, telle qu'un réseau 4G fiable ou une connexion par fibre optique. Cela garantit un transfert rapide et sans heurts de vos fichiers, améliorant ainsi l'expérience utilisateur globale et facilitant une exécution de commande plus efficace.
        </p>
        <br></br>
        <p style={{fontSize: "16px"}}> NB: Les utilisateurs doivent vérifier de manière indépendante si un matériau imprimé convient à leur application particulière et à l'usage auquel il est destiné.</p>
    </div>
    </div>
    </div>
    </>
  );
}

export default MessageComponent;
