import React, { useState, useEffect } from "react";
import 'assets/styles/OrderConfirmation.css';
import ClientOrder from "components/Cards/CardClientOrder";

const AcceptOrder = ({ onConfirm, onReject }) => {
  const [activeLink, setActiveLink] = useState("");
  const [orders, setOrders] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortedOrders, setSortedOrders] = useState([]);


  useEffect(() => {
    // Fetch orders from your API and set them in state
    const fetchOrders = async () => {
      try {
        const response = await fetch("https://worlddigitaldental.com/wddapi/orders/enattente");
        const data = await response.json();
        setOrders(data.orders);
        setSortedOrders(data.orders); // Initially, set sortedOrders to be the same as orders
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    fetchOrders();
  }, []); // Run this effect only once on component mount

  const isLinkActive = (link) => {
    return activeLink === link;
  };

  const handleConfirm = (order) => {
    // Logic for confirming the order
    console.log("Order confirmed:", order);
    
  };

  const handleReject = (order) => {
    // Logic for rejecting the order
    console.log("Order rejected:", order);
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchTerm(searchTerm);
  
    // Filter orders based on the search term only if orders is defined
    if (orders) {
      const filteredOrders = orders.filter((order) =>
        `${order.id}-${order.user_id}`.includes(searchTerm.toLowerCase())
      );
      setSortedOrders(filteredOrders);
    }
  };
  
  const handleSortByDate = () => {
    // Sort orders by date only if sortedOrders is defined
    if (sortedOrders) {
      const sortedByDate = [...sortedOrders].sort((a, b) => new Date(b.date) - new Date(a.date));
      setSortedOrders(sortedByDate);
    }
  };

  return (
    <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
      <div className="flex justify-between mb-4">
        <input
          type="text"
          placeholder="Recherche par ID de commande"
          value={searchTerm}
          onChange={handleSearch}
          className="px-2 py-1 border border-gray-300 rounded"
          />
        <button
          onClick={handleSortByDate}
          className="bg-blue-500 text-white px-4 py-2 rounded"
        >
          Trier par date
        </button>
      </div>
      {orders ? (
        orders.length === 0 ? (
          <p className="text-center text-2xl font-bold py-20">Aucune commande disponible.</p>
        ) : (
          <div className="">
            {sortedOrders.map((order) => (
              <ClientOrder
                key={order.id}
                order={order}
                onConfirm={handleConfirm}
                onReject={handleReject}
              />
            ))}
          </div>
        )
      ) : (
        <p className="text-center text-2xl font-bold py-20">Il n'y a pas de commande pour l'instant..</p>
      )}
    </div>
  );
  
};

export default AcceptOrder;
