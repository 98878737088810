import React, { useState, useEffect } from "react";
import 'assets/styles/OrderConfirmation.css';
import { useNavigate } from "react-router-dom";
import ConfirmOrder from "components/Cards/CardConfirmOrder";

const ConfirmWaitingOrder = ({ onConfirm, onReject }) => {
  const [activeLink, setActiveLink] = useState("");
  const [orders, setOrders] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortedOrders, setSortedOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const ordersPerPage = 4; // Number of orders to display per page
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch orders from your API and set them in state
    const fetchOrders = async () => {
      try {
        const response = await fetch("https://worlddigitaldental.com/wddapi/orders/aconfirme");
        const data = await response.json();
        setOrders(data.orders);
        setSortedOrders(data.orders); // Initially, set sortedOrders to be the same as orders
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    fetchOrders();
  }, []); // Run this effect only once on component mount

  const isLinkActive = (link) => {
    return activeLink === link;
  };

  const handleConfirm = (order) => {
    // Logic for confirming the order
    console.log("Order confirmed:", order);
    navigate("/admin/order_rejected");
  };

  const handleReject = (order) => {
    // Logic for rejecting the order
    console.log("Order rejected:", order);
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchTerm(searchTerm);

    // Filter orders based on the search term
    const filteredOrders = orders
      ? orders.filter((order) =>
          `${order.id}-${order.user_id}`.includes(searchTerm.toLowerCase())
        )
      : [];

    setSortedOrders(filteredOrders);
  };

  const handleSortByDate = () => {
    // Sort orders by date only if sortedOrders is defined
    if (sortedOrders) {
      const sortedByDate = [...sortedOrders].sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );
      setSortedOrders(sortedByDate);
    }
  };

  // Calculate total pages based on ordersPerPage
  const totalPages = sortedOrders ? Math.ceil(sortedOrders.length / ordersPerPage) : 0;
  // Pagination functions
  const goToPage = (page) => {
    setCurrentPage(page);
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Calculate the range of orders to display for the current page
  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = sortedOrders ? sortedOrders.slice(indexOfFirstOrder, indexOfLastOrder) : [];


  return (
    <>
    <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
      <div className="flex justify-between mb-4">
        <input
          type="text"
          placeholder="Recherche par ID de commande"
          value={searchTerm}
          onChange={handleSearch}
          className="px-2 py-1 border border-gray-300 rounded"
        />
        <button
          onClick={handleSortByDate}
          className="bg-blue-500 text-white px-4 py-2 rounded"
        >
          Trier par date
        </button>
      </div>
      {orders === undefined || currentOrders === undefined || currentOrders.length === 0 ? (
        <p className="text-center text-2xl font-bold py-20">
          Aucune commande en attente.
        </p>
      ) : (
        <>
          <div className="grid grid-cols-3 order-grid">
            {currentOrders.map((order) => (
              <ConfirmOrder
                key={order.id}
                order={order}
                onConfirm={handleConfirm}
                onReject={handleReject}
              />
            ))}
          </div>
          <div className="flex-grow" />
          <div className="fixed bottom-0 left-0 w-full bg-white p-8 flex justify-center">
          <button className="pagination-btn" onClick={prevPage} disabled={currentPage === 1}>
              Previous
            </button>
            {Array.from({ length: totalPages }, (_, i) => (
              <button
                key={i + 1}
                onClick={() => goToPage(i + 1)}
                disabled={currentPage === i + 1}
                className={`pagination-btn ${currentPage === i + 1 ? 'active' : ''}`}
              >
                {i + 1}
              </button>
            ))}
            <button className="pagination-btn" onClick={nextPage} disabled={currentPage === totalPages}>
              Next
            </button>
          </div>
        </>
      )}
    </div>
    </>
  );
};

export default ConfirmWaitingOrder;
