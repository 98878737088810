import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// components

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminSidebar from "components/Sidebar/AdminSidebar.js";
import HeaderStatsEmpty from "components/Headers/HeaderStatsEmpty.js";

// views
import AcceptUsers from "views/admin/AcceptUserRequest";
import AcceptOrder from "views/admin/AcceptOrders";
import ConfirmWaitingOrder from "views/admin/ConfirmOrders";
import PdfUploadPage from "views/admin/AttachezPDF";
import PdfConfirmed from "views/admin/PDFconfirmed";
import OrderFirstConfirmation from "views/admin/OrderConfirmed";
import AccountConfirmed from "views/admin/AccountConfirmed";
import ShowDetails from "views/admin/ShowDetails";
import ConfirmedByUser from "views/admin/CofirmedOrderByUser";
import OrderCloture from "views/admin/CommandesCloturee";
import OrderRejetCard from "views/admin/CommandesRjeter"
import AccountRejected from "views/admin/AccountRejected";
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated'
import OrderRejectionPage from "views/admin/RejectOrder";
import OrderRejectionConfirmation from "views/admin/OrderRejected";
import OrderDeleted from "views/admin/OrderDeleted";
import OrderDelivered from "views/admin/Delivered";
import AllUsers from "views/admin/User";
import UserOrders from "views/admin/UserOrders";

export default function Admin() {

  const isAuthenticated = useIsAuthenticated()
  const auth = useAuthUser();

  if(isAuthenticated() && auth?.name === "admin"){

  return (
    <>
      <AdminSidebar />
      <div className="relative md:ml-64 bg-blueGray-100 h-screen">
        <AdminNavbar />
        {/* Header */}
        <HeaderStatsEmpty/>
        
        <div className="flex flex-col ">
        <div className="px-4 md:px-10 mx-auto w-full -m-24" style={{ backgroundColor: "#f0f0f0" }}>   
        
          <Routes>
            <Route path="users_requests" element={<AcceptUsers/>} />
            <Route path="commandes" element={<AcceptOrder/>} />
            <Route path="supprimer" element={<OrderDeleted/>} />
            <Route path="all_users" element={<AllUsers/>} />
            <Route path="commandes_en_attente" element={<ConfirmWaitingOrder/>} />
            <Route path="confirmedbyyser/pdf/:orderId" element={<PdfUploadPage/>} />
            <Route path="pdf_envoyee" element={<PdfConfirmed/>}/> 
            <Route path="order_first_confirmation" element={<OrderFirstConfirmation/>} />
            <Route path="order_rejected" element={<OrderRejectionConfirmation/>} />
            <Route path="accept_user" element={<AccountConfirmed/>} />
            <Route path="commandes/commande/:orderId" element={<ShowDetails/>} />
            <Route path="rejeter/:orderId" element={<OrderRejectionPage/>} />
            <Route path="orders/:userId" element={<UserOrders/>} />
            <Route path="confirmedbyyser" element={<ConfirmedByUser/>} />
            <Route path="cloturee" element={<OrderCloture/>} />
            <Route path="rejeter" element={<OrderRejetCard/>} />
            <Route path="reject_user" element={<AccountRejected/>} />
            <Route path="delivered" element={<OrderDelivered/>} />

            {/* <Route path="/admin" render={() => <Redirect to="/admin/users_requests" />} /> */}
            <Route path="*" element={<Navigate replace to="/admin/users_requests" />} />
          </Routes>
          <br></br>
          <br></br>
          </div>
        </div>
      </div>
    </>
  );
  }
  else{
    return (
        <Navigate to="/auth/login" />
    );
  }
}
