import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";
import createRefresh from 'react-auth-kit';
import axios from "axios";

// layouts
import Admin from "layouts/Admin.js";
import User from "layouts/User.js";
import Auth from "layouts/Auth.js";

// views without layouts
import Index from "views/Index.js";
import ChirGuidee from "views/ChirgurieGuidee.js";
import Aligneurs from "views/Aligneurs.js";
import Contact from "views/Contact.js";
import GuideChirurgical from "views/GuideChirurgical.js";
import SavoirPlus from "views/SavoirPlus.js";

import AuthProvider from "react-auth-kit"
import createStore from 'react-auth-kit/createStore';
import RequireAuth from "react-auth-kit";


const store = createStore({
  authName:'_auth',
  authType:'localStorage',
  cookieDomain: window.location.hostname,
  cookieSecure: window.location.protocol === 'http:',
});

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider store={store}>
    <BrowserRouter>
        <Routes>
          <Route path="admin/*" element={<Admin/>} />
          <Route path="auth/*" element={<Auth/>} />
          {/* add routes without layouts */}
          <Route path="/chirgurie_guidee" element={<ChirGuidee/>} />
          <Route path="/aligneurs" element={<Aligneurs/>} />
          <Route path="/contact" element={<Contact/>} />
          <Route path="/guide_chirurgical" element={<GuideChirurgical/>} />
          <Route path="/savoirplus" element={<SavoirPlus/>} />
          <Route path="/" element={<Index/>} />
          <Route path="user/*" element={
              <User/>
              } />
          {/* add redirect for the first page */}
          {/* <Route path="*" render={() => <Redirect to="/" />} /> */}
          {/* <Navigate to="/" /> */}
        
          {/* Can also use a named `children` prop */}
          {/* <Route path="/user/:id" children={<User />} />
          <Route path="/admin/:id" children={<Admin />} /> */}
          <Route path="*" element={<Navigate replace to="/" />} />
       </Routes>
    </BrowserRouter>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);


// function App() {
//   return (
//     // <AuthProvider store={store}>
//     <BrowserRouter>
//       <Routes>
//         <Route path="admin/*" element={<Admin/>} />
//         <Route path="auth/*" element={<Auth/>} />
//         {/* add routes without layouts */}
//         <Route path="/profile" element={<Profile/>} />
//         <Route path="/chirgurie_guidee" element={<ChirGuidee/>} />
//         <Route path="/aligneurs" element={<Aligneurs/>} />
//         <Route path="/contact" element={<Contact/>} />
//         <Route path="/guide_chirurgical" element={<GuideChirurgical/>} />
//         <Route path="/savoirplus" element={<SavoirPlus/>} />
//         <Route path="/" element={<Index/>} />
//         <Route path="user/*" element={<User/>} />
//         {/* add redirect for the first page */}
//         {/* <Route path="*" render={() => <Redirect to="/" />} /> */}
//         {/* <Navigate to="/" /> */}
      
//       {/* Can also use a named `children` prop */}
//       {/* <Route path="/user/:id" children={<User />} />
//       <Route path="/admin/:id" children={<Admin />} /> */}
//         <Route path="*" element={<Navigate replace to="/" />} />
//       </Routes>
//     </BrowserRouter>
    
//   );
// };

// const WrappedApp = injectContext(App);

// ReactDOM.render(<WrappedApp />, document.getElementById("root"));
