import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// components

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";
import HeaderStatsEmpty from "components/Headers/HeaderStatsEmpty.js";

// views

import Dashboard from "views/user/Dashboard.js";
import Maps from "views/user/Maps.js";
import Commandes from "views/user/Commandes.js";
import VosCommandes from "views/user/VosCommandes.js";
import Messages from "views/user/Messages.js";
import Guide_chirurgical from "views/user/GuideChirurgical.js";
import Aligneurs from "views/user/Aligneurs";
import Goutieres from "views/user/Goutieres";
import ModelesDentaires from "views/user/ModelesDentaires";
import GC_PCI from "views/user/GC_PCI";  
import GC_PC from "views/user/GC_PC";
import GC_P from "views/user/GC_P";
import OrderConfirmation from "views/user/OrderConfirmation";
import PaymentMethods from "views/user/PaymentMethod";
import Confirmed from "views/user/Confirmed";
import ToBeConfirmed from "views/user/To_be_confirmed";
import OrderRejectionPage from "views/user/OrderRejection";
import RejectionP from "views/user/RejectionReason";
import OrderPaymentPage from "views/user/MoneyAmount";
import PaymentUpload from "views/user/PaymentUpload";
import CommandTermination from "views/user/CommandesTerminer";
import CommandRejection from "views/user/CommandesRejeter";
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated'
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import MessageSent from "views/user/MessageSent";


export default function User() {
  const isAuthenticated = useIsAuthenticated()
  const auth = useAuthUser();

  if(isAuthenticated() && auth?.name === "user"){
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100 h-screen">
        <AdminNavbar />
        {/* Header */}
        <HeaderStatsEmpty/>
        
        <div className="flex flex-col ">
        <div className="px-4 md:px-10 mx-auto w-full -m-24" style={{ backgroundColor: "#f0f0f0" }}>   

        {/* <AuthProvider authType={"cookie"} authName={"_auth_t"}> */}
          <Routes>
            <Route path="dashboard" element={<Dashboard/>} />
            <Route path="messages" element={<Messages/>} />
            <Route path="confirmation" element={<OrderConfirmation/>} />
            <Route path="nouvelle_commande" element={<Commandes/>} />
            <Route path="message_sent" element={<MessageSent/>} />
            <Route path="nouvelle_commande/guide_chirurgical" element={<Guide_chirurgical/>} />
            <Route path="payment/:orderId" element={<PaymentMethods/>} />
            <Route path="confirmed" element={<Confirmed/>} />
            <Route path="to_confirm" element={<ToBeConfirmed/>} />
            <Route path="confirmation/rejection/:orderId" element={<OrderRejectionPage/>} />
            <Route path="rejected" element={<RejectionP/>} />
            <Route path="confirmation/amount/:orderId" element={<OrderPaymentPage/>} />
            <Route path="payment_upload/:orderId" element={<PaymentUpload/>} />
            <Route path="termination" element={<CommandTermination/>} />
            <Route path="rejection" element={<CommandRejection/>} />

            <Route path="nouvelle_commande/guide_chirurgical" element={<Guide_chirurgical/>} />
            <Route path="nouvelle_commande/guide_chirurgical/guide_chirurgical_pci" element={<GC_PCI/>} />
            <Route path="nouvelle_commande/guide_chirurgical/guide_chirurgical_pc" element={<GC_PC/>} />
            <Route path="nouvelle_commande/guide_chirurgical/guide_chirurgical_p" element={<GC_P/>} />

            <Route path="nouvelle_commande/aligneurs" element={<Aligneurs/>} />
            <Route path="nouvelle_commande/goutieres" element={<Goutieres/>} />
            <Route path="nouvelle_commande/modeles_dentaires" element={<ModelesDentaires/>} />
            <Route path="commandes" element={<VosCommandes/>} />
            <Route path="nouvelle_commande/first" element={<Maps/>} />
            
            {/* <Route path="/user" render={() => <Redirect to="/user/dashboard" />} /> */}
            <Route path="*" element={<Navigate replace to="/user/dashboard" />} />
          </Routes>
          {/* </AuthProvider> */}
          </div>
        </div>
      </div>
    </>
  );
  } else {
    return (
        <Navigate to="/auth/login" />
    );
  }
}
