import React, { useState, useEffect } from "react";
import 'assets/styles/OrderConfirmation.css';
import UserRequests from "components/Cards/CardAdminUser";

const AcceptUsers = () => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch("https://worlddigitaldental.com/wddapi/users");
        const data = await response.json();
        if (Array.isArray(data.userss)) {
          setUsers(data.userss);
        } else {
          console.error("Data from API is not an array:", data);
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  const handleConfirm = async (user) => {
    try {
      const response = await fetch(`https://worlddigitaldental.com/wddapi/users/${user.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ action: "accepter", doneby : "admin" }),
      });
      if (response.ok) {
        // Update the local state or perform additional actions
        console.log("User accepted:", user);
        const payload = {
            username: user.username,
            email: user.email,
        };

        // Make a POST request to your backend API
        fetch('https://worlddigitaldental.com/wddapi/message/send_email_to_user', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
        .then(response => response.json())
        .then(data => {
            console.log('Success:', data);
            alert('Email sent successfully!');
        })
        .catch((error) => {
            console.error('Error:', error);
            alert('Failed to send email.');
        });


      } else {
        console.error("Failed to accept user:", response.statusText);
      }
    } catch (error) {
      console.error("Error accepting user:", error);
    }
  };

  const handleReject = async (user) => {
    try {
      const response = await fetch(`https://worlddigitaldental.com/wddapi/users/${user.id}`, {
        method: "DELETE",
      });
      if (response.ok) {
        // Update the local state or perform additional actions
        console.log("User declined:", user);
      } else {
        console.error("Failed to decline user:", response.statusText);
      }
    } catch (error) {
      console.error("Error declining user:", error);
    }
  };

  const filteredUsers = users.filter((user) =>
    user.is_approved === false && user.username.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
    <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
      {filteredUsers.length === 0 ? (
        <>
        <div className=" mt-20">
        <p className="text-center text-2xl font-bold py-24">Il n'y a aucun utilisateur à approuver pour le moment</p>
        </div>
        </>
      ) : (
        <div className="">
          {filteredUsers.map((user) => (
            <UserRequests
              key={user.id}
              user={user}
              onConfirm={() => handleConfirm(user)}
              onReject={() => handleReject(user)}
            />
          ))}
        </div>
      )}
    </div>
    </>
  );
};

export default AcceptUsers;
