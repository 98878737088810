// OrderConfirmationPage.js
import React, { useState, useEffect } from "react";
import 'assets/styles/OrderConfirmation.css';
import OrderRejected from "components/Cards/CardOrderRejection";
import { useNavigate } from "react-router-dom";
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';

const CommandRejection = ({ onDelete }) => {

  const [activeLink, setActiveLink] = useState("");
  const [orders, setOrders] = useState([]); // Just for now. we need to change orders1 ==> orders in the dynamic part
  const navigate = useNavigate();
  const auth = useAuthUser();
  const id = auth.id;

  useEffect(() => {
    // Fetch orders from your API and set them in state
    const fetchOrders = async () => {
      try {
        const response = await fetch(`https://worlddigitaldental.com/wddapi/orders/${id}/rejected`);
        const orderData = await response.json();
        setOrders(orderData.orders);

      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    fetchOrders();
  }, [id]);// Run this effect only once on component mount

  const handleDelete = async (order) => {
    try {
      const response = await fetch(`https://worlddigitaldental.com/wddapi/orders/${order.id}`, {
        method: "DELETE",
      });
      if (response.ok) {
        onDelete(order);
      } else {
        throw new Error("Error deleting order");
      }
    } catch (error) {
      console.error("Error deleting order:", error);
    }
    console.log("Order Deleted:", order);
  };

  if (!orders || orders.length === 0) {
    // Loading state or return null
    return (
      <>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
          <p className="text-center text-2xl font-bold py-4">
            Aucune commande rejetée.
          </p>
      </div>
      </>
    );
  }

  return (
    <>
    {orders && orders.length > 0 ? ( 
    <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
      <div className="grid grid-cols-3 order-grid">
        {orders.map((order) => (
          <OrderRejected
          key={order.id}
          order={order}
          onDelete={handleDelete}
        />
        ))}
      </div>
    </div>
    ) : 
    (
      <div className="text-center">
        <h2 className="text-2xl font-bold">No orders found</h2>
        <p className="text-gray-500">Try creating some orders</p>
      </div>
    )}
    </>
  );
};

export default CommandRejection;
