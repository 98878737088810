import React, { useState} from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from 'axios';

export default function ResetPassword() {
  
    const { token } = useParams();
    const [newPassword, setNewPassword] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
  
    const handleResetPassword = async () => {
      try {
        const response = await fetch(`https://worlddigitaldental.com/wddapi/reset_password/${token}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ new_password: newPassword }),
        });

  
        const data = await response.json();
        setMessage('Mot de passe changé avec succès');

        if (response.status === 200) {
            // Handle a successful submission, e.g., show a success message, redirect, etc.
            console.log('Form submitted successfully');
            navigate('/auth/password_changed');
          }
      } catch (error) {
        console.error('Error:', error);
        setMessage('An error occurred.');
      }
    };
  


  return (
    <>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-center mb-3 ">
                  <h6 className="text-blueGray-500 text-sm font-bold">
                    Connectez-vous à
                  </h6>
                </div>
                <img 
                src={require("assets/img/WDD.png")} width={200} 
                className="mx-auto h-16 w-auto"
                />

                <hr className="mt-6 border-b-1 border-blueGray-300" />
              </div>
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <div className="text-blueGray-400 text-center mb-3 font-bold">
                  <small>Envoyez un lien à votre e-mail pour changer votre mot de passe</small>
                </div>
                <form>
                <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      nouveau Mot de passe
                    </label>
                    <input
                      type="password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Password"
                    />
                  </div>

                  {message && (
                    <div className="text-red-500">
                        Une erreur s'est produite.
                    </div>
                  )}

                  <div className="text-center mt-6">
                    <button
                      className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="button"
                      onClick={handleResetPassword}
                      style={{
                      backgroundColor: '#14939C',
                    }}
                    >
                      Changez le mot de passe
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="flex flex-wrap mt-6 relative">
              <div className="text-right">
                <Link to="/auth/register" className="text-blueGray-200">
                  <small>Créer un nouveau compte</small>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
