// OrderItem.js
import React,{useState} from "react";
import { useNavigate } from "react-router-dom";

const OrderRejetCard = ({ order, onReject }) => {

  const [rejectionReason, setRejectionReason] = useState(null); // State for STL download error
  const navigate = useNavigate();

  
  const handleDelete = async () => {    
    try {
      const response = await fetch(`https://worlddigitaldental.com/wddapi/orders/${order.id}`, {
        method: "DELETE",
      });
      if (response.ok) {
        onReject(order);

      } else {
        throw new Error("Error deleting order");
      }
    } catch (error) {
      console.error("Error deleting order:", error);
    }

  };

  const showRejectionReason = () => {
    if (order.admin_rejection_reason) {
      setRejectionReason(order.admin_rejection_reason);
    } else if (order.user_rejection_reason) {
      setRejectionReason(order.user_rejection_reason);
    }
    else {
      setRejectionReason("Aucune raison de rejet disponible pour cette commande.");
    }
  };
  
  
  const buttonStyle = {
      backgroundColor: "#4CAF50", // Green when checked, gray when unchecked
      color: "white", // White text when checked, dark gray text when unchecked
      cursor: "pointer", // Pointer cursor when checked, not-allowed when unchecked
    };

  return (
          <div key={order.id} className="order-item border p-4 mb-4 flex flex-col items-start shadow-lg rounded-lg bg-blueGray-100 border-0">
            <h3 className="text-lg font-bold mb-2">{order.username} : {order.id}-{order.user_id}</h3>
            <p>Email: {order.email}</p>
            <p>Telephone: {order.mobile}</p>
            <p>Type: {order.type}</p>
            <p>Date: {order.date}</p>

            <button
              className="bg-blue-500 text-white px-4 py-2 mt-2 rounded"
              onClick={showRejectionReason}
              style={{ backgroundColor: "#14939C" }}
            >
              Afficher les raisons de rejet
            </button>

            {rejectionReason && (
              <div className="text-red-500 mt-2 ml-3 font-bold">
                {rejectionReason}
              </div>
            )}


            <button
              className="bg-red-500 text-white px-4 py-2 mt-2 rounded"
              onClick={handleDelete}
            //   style={{ backgroundColor: "#14939C" }}
            >
              Delete
            </button>
          </div>
  );
};

export default OrderRejetCard;
