// OrderConfirmationPage.js
import React, { useState, useEffect } from "react";
import 'assets/styles/OrderConfirmation.css';
import { useNavigate } from "react-router-dom";
import ConfirmOrderbyUser from "components/Cards/CardOrderTermination";
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';


const CommandTermination = ({ onDelete }) => {

  const [activeLink, setActiveLink] = useState("");
  const [orders, setOrders] = useState([]); // Just for now. we need to change orders1 ==> orders in the dynamic part
  const navigate = useNavigate();

  const auth = useAuthUser();
  const id = auth.id;

  useEffect(() => {
    // Fetch orders from your API and set them in state
    const fetchOrders = async () => {
      try {
        const response1 = await fetch(`https://worlddigitaldental.com/wddapi/orders/${id}/terminated`);
        const orderData1 = await response1.json();

        const response2 = await fetch(`https://worlddigitaldental.com/wddapi/orders/${id}/delivered`);
        const orderData2 = await response2.json();
  
        
        const combinedOrders = [...orderData1.orders, ...orderData2.orders];
        
        setOrders(combinedOrders);

      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    fetchOrders();
  }, [id]); // Run this effect only once on component mount

  const handleDelete = async (order) => {
    // Logic for rejecting the order
    try {
      const response = await fetch(`https://worlddigitaldental.com/wddapi/orders/${order.id}`, {
        method: "DELETE",
      });
      if (response.ok) {
        onDelete(order);
      } else {
        throw new Error("Error deleting order");
      }
    } catch (error) {
      console.error("Error deleting order:", error);
    }
    console.log("Order Deleted:", order);
    // Navigate to deleted page (devolop the deleted page)
  };

  if (!orders || orders.length === 0) {
    // Loading state or return null
    return (
      <>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
          <p className="text-center text-2xl font-bold py-4">
            Aucune commande terminée.
          </p>
      </div>
      </>
    );
  }

  return (
    <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
      <div className="grid grid-cols-3 order-grid">
        {orders.map((order) => (
          <ConfirmOrderbyUser
          key={order.id}
          order={order}
          onDelete={handleDelete}
        />
        ))}
      </div>
    </div>
  );
};

export default CommandTermination;
