import React,{useState} from "react";
import { useNavigate } from "react-router-dom";

const Users = ({ user, onReject }) => {


    // to handle the confirm, reject and ignore actions
    const navigate = useNavigate();

    const handleIgnore = async () => {
      const shouldDelete = window.confirm("Êtes-vous sûr de vouloir supprimer cet utilisateur?");
      
      if (shouldDelete) {
        try {
            const ordersdepete = await fetch(`https://worlddigitaldental.com/wddapi/allorders/delete/${user.id}`, {
              method: "DELETE",
            });

            const response = await fetch(`https://worlddigitaldental.com/wddapi/users/${user.id}`, {
              method: "DELETE",
            });
            if (response.ok) {
              // Update the local state or perform additional actions
              console.log("User declined:", user);
              navigate("/admin/reject_user")
            } else {
              console.error("Failed to decline user:", response.statusText);
            }
          } catch (error) {
            console.error("Error declining user:", error);
        }
      }
    };

    const handleShowOrders = async () => {
        console.log("User id:", user.id);
        navigate(`/admin/orders/${user.id}`);
    }

    
    const buttonStyle = {
        backgroundColor: "#4CAF50", // Green when checked, gray when unchecked
        color: "white", // White text when checked, dark gray text when unchecked
        cursor: "pointer", // Pointer cursor when checked, not-allowed when unchecked
      };

  return (
<div key={user.id} className="order-item border p-2 mb-2 flex flex-col items-start shadow-lg rounded-lg bg-blueGray-100 border-0">
    <div className="grid grid-cols-1 order-grid">
        <p><span className="font-bold">Nom d'utilisateur:</span> {user.username}</p>
        <p><span className="font-bold">Nom:</span> {user.name}</p>
        <p><span className="font-bold">Prénom:</span> {user.lastname}</p>
        <p><span className="font-bold">Sexe:</span> {user.gender}</p>
        <p><span className="font-bold">Travail:</span> {user.user_type}</p>
        <p><span className="font-bold">Email:</span> {user.email}</p>
        <p><span className="font-bold">Num:</span> {user.fix_number}</p>
        <p><span className="font-bold">Mobile:</span> {user.mobile_number}</p>
        <p><span className="font-bold">Adresse:</span> {user.adress}</p>
        <p><span className="font-bold">Ville:</span> {user.ville}</p>
        <p><span className="font-bold">Pays:</span> {user.pays}</p>
        <p><span className="font-bold">Matricul Fiscal:</span> {user.matricule}</p>
    </div>
    <div className="flex justify-end">
        <div className="flex">
            <button
                className="bg-red-500 text-white px-12 py-2 mt-2 mr-2 rounded"
                onClick={handleIgnore}
            >
                Supprimer
            </button>
            <button
                className="bg-blue-500 text-white px-12 py-2 mt-2 rounded"
                onClick={handleShowOrders}
            >
                Afficher les commandes
            </button>
        </div>
    </div>
    </div>

  );
};

export default Users;
